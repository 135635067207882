import {
  WEBSITE_NAVIGATION_PAGE_TYPES,
  WEBSITE_NAVIGATION_VIEW_MODE,
  WEBSITE_PAGE_TYPES,
} from 'packages/enum'

export const VIEW_MODE_FEATURES = {
  [WEBSITE_NAVIGATION_VIEW_MODE.MAIN]: {
    pagesToShow: [], // all
    singlePageCreation: false, // else {type, blockType}
    mainPagesCreatable: true,
    footerPagesCreatable: true,
    withTemplatesPanel: true,
    withSearch: false,
    withSlideshow: true,
    pagesDraggable: true,
    pagesDeletable: true,
    pagesCustomizable: true,
    albumsDraggable: true,
    albumsDeletable: true,
    albumsCustomizable: true,
    albumsCreatable: true,
    pageClickDisabled: false,
  },
  [WEBSITE_NAVIGATION_VIEW_MODE.ALBUM_ITEM_MOVING]: {
    pagesToShow: [
      WEBSITE_NAVIGATION_PAGE_TYPES.ALBUM_LIST,
      WEBSITE_NAVIGATION_PAGE_TYPES.EXPANDED_ALBUM_LIST,
    ],
    singlePageCreation: { type: WEBSITE_PAGE_TYPES.ALBUM_LIST },
    mainPagesCreatable: true,
    footerPagesCreatable: true,
    withTemplatesPanel: false,
    withSearch: true,
    withSlideshow: false,
    pagesDraggable: false,
    pagesDeletable: false,
    pagesCustomizable: false,
    albumsDraggable: false,
    albumsDeletable: false,
    albumsCustomizable: false,
    albumsCreatable: true,
    pageClickDisabled: true,
  },
}
