import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'

import { useInterval } from 'packages/helpers/useInterval'

const ORDER_UPDATE_TIMEOUT = 1000
const MAX_ERRORS_COUNT = 3

const getDefaultRequestArgs = (items) => ({ itemIds: items.map((el) => el.id) })

export const useItemsOptimisticRequests = ({
  items: initialItems,
  refetchItems = async () => {},
  optimisticRequest = () => {},
  loaderStart = () => {},
  loaderStop = () => {},
  openInfoModal = () => {},
  closeInfoModal = () => {},
  requestTimeout = ORDER_UPDATE_TIMEOUT,
  maxErrorsCount = MAX_ERRORS_COUNT,
  successMessage = '',
  errorMessage = '',
  loaderMessage = '',
  optimisticRequestName = '',
  getRequestArgs = getDefaultRequestArgs,
  redirectTo,
}) => {
  const defaultError = 'layout.error.desc'
  const [data, setData] = useState(initialItems || [])
  const [showModalError, setShowModalError] = useState(false)
  const sendData = useRef([])
  const requestErrors = useRef([])
  const isRequestLoading = useRef(false)
  const isOrderChanged = useRef(false)
  const canSendRequest = useRef(true)
  // пользователь пытался уйти со страницы
  const isUserClosedWindow = useRef(false)
  const refetchAfterOrderUpdate = useRef(false)
  const nextLocation = useRef(null)

  const handleOrderUpdate = (dataToSend) => {
    isRequestLoading.current = true
    const args = getRequestArgs(dataToSend)

    optimisticRequest(args).then((res) => {
      if (!get(res, `data.${optimisticRequestName}.success`)) {
        const error = get(res, `data.${optimisticRequestName}.errors._error`, defaultError)
        const errors = requestErrors.current.concat([error])
        requestErrors.current = errors
        // если стек ошибок переполнен или пользователь хотел покинуть страницу
        if (errors.length >= maxErrorsCount || isUserClosedWindow.current || nextLocation.current) {
          if (isUserClosedWindow.current || nextLocation.current) {
            loaderStop()
          }
          setShowModalError(true)
          /* eslint-disable */
          errors.map((err) => console.error(err))
          requestErrors.current = []
        }
      } else {
        if (isEqual(dataToSend, sendData.current)) {
          isOrderChanged.current = false
          sendData.current = []
        }
        //  сброс ошибок при удачной отправке запроса
        if (requestErrors.current.length) {
          requestErrors.current = []
        }

        if (isUserClosedWindow.current) {
          loaderStop()
          isUserClosedWindow.current = false
          openInfoModal({
            hideHeader: true,
            subMessageId: successMessage,
            yesMessageId: 'items.info.yes',
          })
        }
        if (nextLocation.current) {
          loaderStop()
          // window.location = nextLocation.current.pathname;
          if (redirectTo) {
            redirectTo(nextLocation.current.pathname)
          } else {
            window.location = nextLocation.current.pathname
          }
        }
      }
      isRequestLoading.current = false
      if (refetchAfterOrderUpdate.current) {
        refetchItems().then(() => {
          refetchAfterOrderUpdate.current = false
        })
      }
    })
  }

  useBeforeunload(() => {
    if (!nextLocation.current) {
      if (isRequestLoading.current || isOrderChanged.current) {
        isUserClosedWindow.current = true
        if (showModalError) {
          setShowModalError(false)
        }
        loaderStart({ content: loaderMessage })
        canSendRequest.current = false
        if (isOrderChanged.current) {
          sendData.current = data
          handleOrderUpdate(data)
        }
        return "You'll lose your data!"
      }
    }
    return false
  })

  useEffect(() => {
    setData(initialItems)
  }, [initialItems])

  useEffect(() => {
    if (showModalError) {
      canSendRequest.current = false
      openInfoModal({
        headerMessageId: 'error.header',
        yesMessageId: 'RETRY',
        buttons: ['yes', 'cancel'],
        subMessageId: errorMessage,
        onClickYes: () => {
          loaderStart()
          closeInfoModal()
          refetchItems().then(() => {
            loaderStop()
            canSendRequest.current = true
          })
          setShowModalError(false)
          requestErrors.current = []
          isOrderChanged.current = false
          sendData.current = []
        },
        onClickCancel: () => {
          closeInfoModal()
          if (nextLocation.current) {
            if (redirectTo) {
              redirectTo(nextLocation.current.pathname)
            } else {
              window.location = nextLocation.current.pathname
            }
          }
          setShowModalError(false)
          requestErrors.current = []
          isOrderChanged.current = false
          sendData.current = []
          canSendRequest.current = true
        },
      })
    }
  }, [
    closeInfoModal,
    errorMessage,
    loaderStart,
    loaderStop,
    openInfoModal,
    redirectTo,
    refetchItems,
    showModalError,
  ])

  useInterval(() => {
    if (requestErrors.current.length < maxErrorsCount) {
      // если изменился порядок и можно отправить запрос
      if (canSendRequest.current && isOrderChanged.current) {
        // если не выполняется предыдущий запрос
        if (!isRequestLoading.current) {
          handleOrderUpdate(sendData.current)
        }
      }
    }
  }, requestTimeout)

  return {
    isRequestLoading: isRequestLoading.current,
    setIsRequestLoading: (loading) => (isRequestLoading.current = loading),
    isOrderChanged: isOrderChanged.current,
    setIsOrderChanged: (isChanged) => (isOrderChanged.current = isChanged),
    data,
    setData,
    canSendRequest: canSendRequest.current,
    setCanSendRequest: (isCanSend) => (canSendRequest.current = isCanSend),
    showModalError,
    setShowModalError,
    isUserClosedWindow: isUserClosedWindow.current,
    setIsUserClosedWindow: (isClosed) => (isUserClosedWindow.current = isClosed),
    nextLocation: nextLocation.current,
    setNextLocation: (location) => (nextLocation.current = location),
    sendData: sendData.current,
    setSendData: (ids) => (sendData.current = ids),
    requestErrors: requestErrors.current,
    setRequestErrors: (errors = []) => (requestErrors.current = errors),
    onOrderUpdate: handleOrderUpdate,
    setRefetchAfterOrderUpdate: (val) => (refetchAfterOrderUpdate.current = val),
  }
}
