import { formatMessagesList } from 'packages/helpers/Helper'

const shared = {
  currency: '{currency, select, EUR {€} USD {$} other {€}}',
}

const messages = {
  'format.currency': shared.currency,
  'page.update.error.header': 'Error',
  'album.delete.header': 'Please confirm action',
  'album.delete.subHeader': 'The album will be deleted.',
  'siteName.update.field.siteName': 'Logo Title',
  'siteName.update.field.siteDescription': 'Logo Subtitle',
  'siteName.update.error.header': 'Error',
  'page.create.error.header': 'Error',
  'domain.update.error.header': 'Error',
  'preview.banner.websiteOffline.title': `
        This website is set <b>offline</b> and will not display to your visitors.
        Its only visible to you, so you can edit it.
        You can set it live in website settings
    `,
  'preview.banner.websiteOffline.trialEnded.title':
    '<b>Your Vsble Pro Max 14-day free trial has expired.</b> To publish your website, please select a subscription plan.',
  'preview.banner.websiteOffline.trialEnded.select.plan': 'Select a plan',
  'preview.banner.pageOffline.title': `
        This page is set <b>offline</b> and will not display to your visitors.
        Its only visible to you, so you can edit it.
        You can set it live in page settings
    `,
  'preview.banner.pageProtected.title':
    'This page is currently protected by a password and not visible to public.',
  'preview.banner.pageProtected.subTitle': `Remove password in <b>Page Settings</b> to unlock the page.`,
  'preview.banner.pageProtected.iconDesc': 'The lock next to the page name indicates it.',
  'preview.banner.confirmEmail.title': 'Please verify your e-mail to unlock all features in your trial period',
  'preview.banner.confirmEmail.button': 'Verify',
  'preview.banner.confirmEmail.modal.title': 'Account Verification',
  'preview.banner.confirmEmail.modal.text': 'Please check your inbox for a verification email and enter the code you received in the field below to confirm your email address.',
  'preview.banner.confirmEmail.modal.email': 'We have sent the code to',
  'preview.banner.confirmEmail.modal.resendCode': 'Resend the code',
  'preview.banner.confirmEmail.modal.codeWasSend': 'Code was sent!',
  'preview.banner.confirmEmail.modal.verified': 'Verified!',
  'album.markerIconTitle.video': 'Video',
  'album.markerIconTitle.description': 'Description',
  'album.markerIconTitle.image': 'Image',
  'album.label.cover': 'Album Cover',
  'album.label.hidden': 'Hidden',
  'album.label.delete': 'Delete',
  'album.notification.movedToAlbum': 'Moved to',
  'upload.modal.error.header': 'Error',
  'upload.modal.error.invalid': 'Invalid url',
  'upload.error.header': 'Upload error',
  'upload.error.ok': 'Ok',
  'upload.error.description': 'You can try it later',
  'upload.error.description.short': 'Internal error',
  'header.preview': 'Preview website',
  'navigation.label.main': 'Main navigation',
  'navigation.label.footer': 'Footer navigation',
  'navigation.search.placeholder': 'Search Category, Album',
  'progressBar.fileUploading': 'Please wait. File is uploading.',
  'progressBar.processing': 'Please wait. Processing...',
  'statistics.total.pageViews': 'TOTAL PAGE VIEWS',
  'statistics.total.visitors': 'TOTAL VISITORS (UNIQUE)',
  'lineChart.label.pageViews': 'Visits',
  'lineChart.label.visitors': 'Unique visitors',
  'mapChart.title': 'Territory',
  'mostViewed.page.title': 'Most viewed Page',
  'mostViewed.album.title': 'Most viewed Album',
  'mostViewed.image.title': 'Most viewed Image',
  'referrals.title': 'Referrals',
  'referrals.column.header.url': 'URL',
  'bootstrap.your.free': 'Your free website address',
  'bootstrap.domain': 'Domain',
  'bootstrap.confirm.change': 'Save changes',
  'bootstrap.confirm.connected': 'Connected',
  'bootstrap.confirm.remove': 'Remove',
  'bootstrap.confirm.': 'Connected',
  'page.field.error.title': 'Page Name cannot be empty',
  'page.field.error.slug': 'For URL slugs use only latin characters, numbers, - and _',
  'page.field.error.pageType': 'Page Type cannot be empty',
  'album.field.error.name': 'Album Name cannot be empty',
  'billing.plan.month': 'per month billed monthly',
  'billing.plan.year': 'per month billed yearly',
  'siteName.delete.header': 'Are you sure?',
  'siteName.delete.subHeader': 'Do you really want to delete?',
  'siteName.delete.button.cancel': 'Cancel',
  'siteName.delete.button.delete': 'Delete',
  'album.label.makeCover': 'Make Cover',
  'album.label.coverImage': 'Image is Cover',
  'album.label.hideImage': 'Hide in Album',
  'pdfTool.modal.header': 'Upload a logo for your PDF header',
  'pdfTool.modal.footer': 'Footer text: this will be displayed on each page of the downloaded PDF',
  'pdfTool.modal.footer.helperText.length': 'Limited number of characters: {current}/{max}',
  'pdfTool.modal.footer.helperText.rows': 'Row limit: {current}/{max}',
  'slideshow.modal.error.default': 'Something went wrong.',
  'slideshow.modal.upload.label':
    'You can upload a single static image or several images. Images will appear in the order they are sorted. You can set slideshow transition speed as well as how images are scaled in the slideshow area.',
  'slideshow.modal.speed.label': 'Transition speed',
  'slideshow.modal.speed.slow': 'slow',
  'slideshow.modal.speed.fast': 'fast',
  'slideshow.modal.type.photo': 'Photo',
  'slideshow.modal.type.video': 'Video',
  'upload.error.subscription.text':
    'Your upload limit has been reached. Delete default system albums to free up the space or subscribe to Vsble PRO for unlimited uploads!',
  'upload.error.subscription.button': 'Subscribe',
  'upload.error.subscription.short': 'Upload limit reached',
  'website.settings.visibility.title': 'Website visibility',
  'website.settings.freeSubDomain.title': 'Free subdomain',
  'website.settings.customDomain.title': 'Custom domain',
  'website.settings.privacy.title': 'Privacy & GDPR',
  'website.settings.visibility.text1': 'General website visibilty',
  'website.settings.visibility.text2':
    'By switching the website to “Offline,” it will not be visible publicly. Instead, the\n message “Website coming soon...” will be displayed.',
  'website.settings.visibility.text3': 'Switch off the default cookie consent solution',
  'website.settings.visibility.text4': 'Remove branding',
  'website.settings.visibility.text5': 'Remove Vsble logo from website footer',
  'website.settings.premium.title': 'Custom Domain',
  'website.settings.premium.text1':
    "Having a custom domain or top-level domain (TLD) for your website adds significant value to your online presence. Not only does it boost your credibility and professionalism, but it also enhances your brand’s prestige, making your site more memorable and trustworthy. From an SEO perspective, a custom domain improves your website’s search engine ranking, helping potential visitors find you more easily. Using a TLD allows you to take full control of your online identity, and it makes your website stand out among competitors with generic URLs. Overall, it’s a smart investment that elevates your website’s visibility and brand authority.",
  'website.settings.premium.text1.1': 'If you do not have a domain, you can buy one and it will be connected automatically to your Vsble website.',
  'website.settings.premium.text2': '{domain}.vsble → www.{domain}.com',
  'website.settings.premium.text3':
    'Enter your domain name in the below field <b>without „www“</b> but with its extension (.com , .fr , .uk.co …). Depending how fast your domain provider can update the DNS settings, your domain will need from \n' +
    '5min. - 72h. to connect with your Vsble website.',
  'website.settings.premium.text4':
    'Login to your domain provider dashboard. In the DNS settings of your domain add two records.',
  'website.settings.premium.text5': '94.130.249.72',
  'website.settings.premium.text6':
    'A www 94.130.249.72 (Add “www” subdomain first, if you don’t have one)',
  'website.settings.premium.text7':
    "Make sure no other A records for these hosts exist. Remove them if necessary. Watch this <a href='{videoLink}' target='_blank' rel='noopener noreferrer'>video</a> how to connect your domain e.g. with GoDaddy here.",
  'website.settings.premium.text10':
    'For trouble shooting refer to this  <a target="_blank" href="{guideLink}">guide</a>.</br></br>' +
    'If you prefer us to connect your domain, you can book connection service for a one time fee of 39€ — <a href="mailto:support@vsble.me?subject=Domain%20Connect%20Request">contact us</a>.',
  'website.settings.premium.text11': '1. Record Type: ',
  'website.settings.premium.text12': 'Connect external domain',
  'website.settings.premium.text13': 'If you already have a custom domain and prefer not to transfer it, you can easily connect it directly to your Vsble website.',
  'website.settings.premium.text14': 'Connect domain',
  'website.settings.premium.text15': 'Buy or transfer and connect a VSBLE custom domain',
  'website.settings.premium.text16': 'Purchase a custom domain from us or transfer your existing one, and connect it instantly to your Vsble website.',
  'website.settings.premium.text17': 'Buy domain',
  'website.settings.premium.select': 'Select domain',
  'website.settings.premium.text18': 'Manage domains',
  'website.settings.premium.buy.connect': '1. Buy or Transfer',
  'website.settings.premium.buy.connect.desc': 'In the next step, you’ll have the option to either purchase a new domain or transfer your existing one.',
  'website.settings.premium.buy.connect.2': '2. Connect',
  'website.settings.premium.buy.connect.2.desc': 'Once you obtained or transfered your domain, enter it below and click connect.',
  'website.settings.privacy.title': 'Privacy & GDPR',
  'website.settings.privacy.text1': 'Cookie bar',
  'website.settings.privacy.text2':
    'The cookie bar is activated by default and blocks all cookies before the visitor gives their consent. Switch the selector to deactivate the cookie banner, e.g. if you want to install your own cookie banner.',
  'website.settings.privacy.text3': 'Block third party fonts by default',
  'website.settings.privacy.text4':
    'Third-party fonts like Google Fonts, Type font, or Adobe fonts are not blocked by default. However, depending on your country, you might need to prevent using third-party fonts before your website visitor consents explicitly. Enable this function to comply with the GDPR regulations of your region.',
  'website.settings.privacy.cookies.enabled': "Cookies enabled",
  'website.settings.privacy.cookies.disabled': "Cookies disabled",
  'website.settings.privacy.fonts.enabled': "Fonts are blocked",
  'website.settings.privacy.fonts.disabled': "Fonts are not blocked",
  'website.footer': "Vsble. All Rights Reserved",
  'website.domain.status.dnsCheckingWrongIPV4': 'IPV4 is configured incorrectly',
  'website.domain.status.dnsCheckingConstantIPV6': 'Сontains IPV6',
  'website.domain.status.wrongIPV4AndConstantIPV6': 'Invalid IPV4 and IPV6 present',
  'website.domain.status.unregistredDomain': 'Error at the DNS ckecking stage',
  'website.domain.status.genericError': 'Unexpected error',
  'website.domain.status.dnsChecking': 'Awaiting DNS validation from provider...',
  'website.domain.status.dnsCheckingFailed': 'DNS checking failed',
  'website.domain.status.dnsValid': 'Setting SSL certificate...',
  'website.domain.status.sertFailed': 'SSL certificate setting failed',
  'website.domain.status.failed': 'Connection failed',
  'website.domain.status.connected': 'Connected',
  'website.domain.status.ssl': 'Awaiting SSL certificate',
  'website.domain.status.configuration': 'Applying configuration',
  'website.verificationEmail.error.email': 'Something went wrong, email not sent',
  'website.verificationEmail.error.code': 'Something went wrong, code not sent',
  'album.item.label.show': 'Show',
  'album.item.label.setOffline': 'Set offline',
  'bootstrap.domain.subheader.text2':
    'All visible websites include a complimentary customizable subdomain, allowing you to publish and exhibit your work. Modify the default subdomain as desired. If it’s available, you can secure it.',
  'fonts.update.placeholder.primary': 'Primary font',
  'fonts.update.error.text': 'Something went wrong.',
  'album.field.error.pageIds': 'You have to choose at least one category page',
  'fonts.update.page.previous': 'Previous',
  'fonts.update.page.next': 'Next',
  'album.name.noTitle': 'No Title',
  'templates.label.anton': 'ANTON',
  'templates.label.anton.black': 'ANTON BLACK',
  'templates.label.sophie': 'SOPHIE',
  'templates.label.sophie.black': 'SOPHIE BLACK',
  'templates.label.nikolai': 'NIKOLAI',
  'templates.label.nikolai.black': 'NIKOLAI BLACK',
  'templates.label.peggy': 'PEGGY',
  'templates.label.peggy.black': 'PEGGY BLACK',
  'templates.label.peggyslideshow': 'PEGGY SLIDESHOW',
  'templates.label.peggyslideshow.black': 'PEGGY SLIDESHOW BLACK',
  'templates.label.jeremy': 'JEREMY',
  'templates.label.jeremy.black': 'JEREMY BLACK',
  'templates.label.jeremyslideshow': 'JEREMY SLIDESHOW',
  'templates.label.jeremyslideshow.black': 'JEREMY SLIDESHOW BLACK',
  'templates.label.paris': 'PARIS',
  'templates.label.paris.black': 'PARIS BLACK',
  'templates.label.brooklyn': 'BROOKLYN',
  'templates.label.brooklyn.black': 'BROOKLYN BLACK',
  'templates.label.newyork': 'NEW YORK',
  'templates.label.newyork.black': 'NEW YORK BLACK',
  'templates.label.sydney': 'SYDNEY',
  'templates.label.sydney.black': 'SYDNEY BLACK',
  'templates.label.berlin': 'BERLIN',
  'templates.label.berlin.black': 'BERLIN BLACK',
  'templates.label.antwerp': 'ANTWERP',
  'templates.label.antwerp.black': 'ANTWERP BLACK',
  'templates.label.barcelona': 'BARCELONA',
  'templates.label.barcelona.black': 'BARCELONA BLACK',
  'templates.label.riga': 'RIGA',
  'templates.label.riga.black': 'RIGA BLACK',
  'templates.label.rigaslideshow': 'RIGA SLIDESHOW',
  'templates.label.rigaslideshow.black': 'RIGA SLIDESHOW BLACK',
  'templates.label.madrid': 'MADRID',
  'templates.label.madrid.black': 'MADRID BLACK',
  'templates.label.pavel': 'PAVEL',
  'templates.label.pavel.black': 'PAVEL BLACK',
  'templates.colorToggle.black': 'Black',
  'templates.colorToggle.white': 'White',
  'slideshow.label.logoSize': 'Logo size',
  'statistics.total.averageTime': 'AVERAGE TIME SPENT ON YOUR SITE',
  'statistics.periodPicker.options.day': 'Day',
  'statistics.periodPicker.options.week': 'Week',
  'statistics.periodPicker.options.month': 'Month',
  'statistics.periodPicker.options.year': 'Year',
  'statistics.periodPicker.options.range': 'Range...',
  'statistics.periodPicker.date': 'Date',
  'statistics.periodPicker.period': 'Period',
  'statistics.periodPicker.useDemo': 'Use Demo',
  'statistics.periodPicker.apply': 'Apply',
  'statistics.line.header': 'Visits over time',
  'statistics.online': 'Online',
  'mostViewed.link.hide': 'hide list',
  'mostViewed.noInfo': 'No Info',
  'statistics.subscription.insights': 'Get insights on',
  'statistics.subscription.pageviews': 'Pageviews & visitors',
  'statistics.subscription.visitors': 'Realtime visitors',
  'statistics.subscription.referralPages': 'Referral pages and links',
  'statistics.subscription.countries': 'Visitors countries',
  'statistics.subscription.mvPage': 'Most viewed page',
  'statistics.subscription.mvAlbum': 'Most viewed album',
  'statistics.subscription.mvImage': 'Most viewed image',
  'statistics.subscription.button': 'Upgrade now',
  'upload.error.fileSize.header': 'Upload error',
  'upload.error.fileSize.ok': 'Ok',
  'upload.error.fileSize.description':
    'Your file exceeds the upload limit, please upload files smaller than 20mb each.',
  'upload.error.fileSize.short': 'File size exceeds 20MB',
  'album.items.loading': 'Album data is saving...',
  'album.items.info.success': 'Album data is saved successfully! You can close the window.',
  'album.items.info.error': 'Album data updating completed with error.',
  'page.albums.loading': 'Page data is saving...',
  'page.albums.info.success': 'Page data is saved successfully! You can close the window.',
  'page.albums.info.error': 'Page data updating completed with error.',
  'items.info.yes': 'OK',
  'page.update.field.textAlign': 'Text align:',
  'page.update.field.language': 'Contact form language',
  'styles.title.header': 'Header',
  'styles.title.logo': 'Logo',

  'styles.title.logoTitle': 'Logo Settings',
  'styles.title.header.sublogo': 'Sublogo Settings',
  'styles.title.mainNavigation.navigation': 'Navigation Elements',
  'styles.title.mainNavigation.navigation.description': 'This control adjusts the appearance and behavior of navigation elements, including arrows, page numbers, and links such as “Back to Main Page“',
  'styles.title.footer': 'Footer',
  'styles.title.burgerMenu': 'Burger Menu',
  'styles.title.contacts': 'Contact Page',
  'styles.form.button.save': 'Save',
  'styles.reset.header': 'Are you sure?',
  'styles.header.scrollable': 'Scrollable Header',
  'styles.header.layoutAlign': 'Header Layout',
  'styles.header.layoutAlign.left': 'Left',
  'styles.header.layoutAlign.right': 'Right',
  'styles.header.layoutAlign.center': 'Center',
  'styles.header.headerColor': 'Header Color',
  'styles.header.headerColor.opacity': 'Header Opacity',
  'styles.header.headerColor.desc': 'The header color settings apply globally across desktop & mobile.',
  'styles.header.navigationLinks': 'Navigation Links',
  'styles.header.navigationLinks.allCaps': 'ALL CAPS',
  'styles.header.navigationLinks.initial': 'Initial',
  'styles.header.navigationLinks.firstCaps': 'First Caps',
  'styles.header.navigationLinks.lowerCase': 'lower case',
  'styles.header.mode.classic': 'Classic Mode',
  'styles.header.mode.burger': 'Burger',
  'styles.logo.title': 'Edit Logo',
  'styles.logo.mode.textLogo': 'Text Logo',
  'styles.logo.mode.customLogo': 'Custom Logo',
  'styles.header.paddingTop': 'Top Padding',
  'styles.header.paddingBottom': 'Bottom Padding',
  'styles.header.width.limit': "Header width limit",
  'styles.header.width.full.wide': "Full-wide",
  'styles.header.width.limited.by.content': "Limited by content",
  'styles.header.position': "Header Position",
  'styles.header.position.fixed': "Fixed",
  'styles.header.position.scrolling': "Scrolling",
  'styles.header.mode.icon': "Icon",
  'styles.header.mode.text': "Text",
  'styles.header.burger.position': "Position",
  'styles.header.burger.style': "Burger Style",
  'styles.title.burgerMenu': 'Burger Menu',
  'styles.burgerMenu.navigationLinks': 'Burger Navigation Links',
  'styles.burgerMenu.layoutAlign': 'Navigation Layout',
  'styles.burgerMenu.navigation.links.color': 'Navigation Links Color',
  'styles.section.label.logo': 'Logo',
  'styles.fontWeight.300': '300',
  'styles.fontWeight.400': '400',
  'styles.fontWeight.500': '500',
  'styles.fontWeight.600': '600',
  'styles.fontWeight.700': '700',
  'styles.fontWeight.800': '800',
  'styles.fontWeight.900': '900',
  'styles.section.label.fontWeight': 'Font Weight',
  'styles.reset.subHeader': 'Do you really want to reset settings?',
  'styles.subscription.header': 'Fonts & Colors',
  'styles.subscription.subheader': 'Create a unique look for your portfolio website',
  'styles.subscription.description':
    'Get control over font type, size, and color for each element of your website, as well as background and navigation elements color.',
  'styles.subscription.upgrade': 'Upgrade',
  'template.change.header': 'Design Change',
  'template.change.message':
    'Your custom font & colors for the currently active design will reset to default. You can make screenshots of custom font & color settings if you want to use them later. All other settings and contents are automatically saved and adjusted to any selected design.',
  'template.change.question': 'Proceed with template change?',
  'template.change.button.ok': 'Yes',
  'template.change.button.cancel': 'Cancel',
  'template.button.select': 'Select {template}',
  'template.slideShowEnabled': 'Landing page<br/>photo & video slideshow',
  'template.button.preview': 'Preview {template}',
  'tabs.tab.fontsAndColors': 'Customization',
  'tabs.tab.mobile': 'Mobile',
  'styles.inputs.fontSize.label': 'Size',
  'styles.inputs.navigationFontSize.label': 'Navigation Links Font Size',
  'styles.inputs.fontSize.value.small': 'Small',
  'styles.inputs.fontSize.value.medium': 'Medium',
  'styles.inputs.fontSize.value.large': 'Large',
  'styles.title.navigation': 'Navigation elements',
  'styles.title.navigation.pageTitle': 'Page Title',
  'styles.footer.layoutAlign': 'Footer Desktop Layout',
  'styles.footer.layoutAlignMobile': 'Footer Mobile Layout',
  'styles.footer.layoutAlign.left': 'Left oriented',
  'styles.footer.layoutAlign.right': 'Right oriented',
  'styles.footer.layoutAlign.center': 'Centered',
  'styles.footer.defaultFooterLinks': 'Default Footer Links',
  'styles.footer.defaultFooterLinks.allCaps': 'ALL CAPS',
  'styles.footer.defaultFooterLinks.firstCaps': 'First Caps',
  'styles.footer.defaultFooterLinks.lowerCase': 'lower case',
  'styles.footer.toggle.showYear': 'Show Year',
  'styles.footer.toggle.showDomainTitle': 'Show Domain Title',
  'styles.footer.toggle.removeBranding': 'Remove Branding',
  'styles.footer.toggle.removeBranding.desc': 'Remove Vsble logo',
  'styles.logo.marginBottom': 'Margin bottom',
  'styles.logo.marginTop': 'Margin top',
  'styles.logo.paddingBottom': 'Padding bottom',
  'styles.logo.paddingTop': 'Padding top',
  'styles.footer.toggle.overSlideshow': 'Over Slideshow',
  'styles.title.albumPage': 'Album Page',
  'styles.title.albumPage.description': 'Description',
  'styles.title.albumPage.imgDescription': 'Image Description',
  'styles.title.albumPage.title': 'Album Title',
  'styles.title.albumPage.page.title': 'Album Page Title',
  'styles.title.photoPage': 'Simple Page',
  'styles.title.photoPage.title': 'Page title',
  'styles.title.imageDetail': 'Image Detail',
  'styles.title.imageDetail.text': 'Text',
  'styles.title.imageDetail.icon': 'Icon',
  'styles.title.imageDetail.text.type': 'Text type',
  'styles.title.slideshow.description': ' Activate the slideshow feature to automatically set it as your website\'s homepage. You can customize the slideshow page by clicking on it.',
   'styles.title.slideshow.button': 'Go to slideshow settings',
  'styles.title.albumListPage.title': 'Album Collection Page',
  'styles.title.textPage': 'Freestyle Page',
  'styles.title.textPage.text': 'Freestyle',
  'styles.title.burger': 'Burger Color',
  'styles.title.hover': 'Hover Color',
  'styles.title.backgroundColor': 'Background Color',
  'styles.button.resetAllSettingsToDefault': 'Reset All Settings to Default',
  'styles.form.button.resetToDefault': 'Reset to Default',
  'slideshow.footer.title': 'Footer',
  'slideshow.footer.description': 'By default the footer is always visible over the slideshow. You can apply footer color & transparency in footer settings.',
  'slideshow.footer.toggle.over.slideshow': 'Show on mouse over',
  'slideshow.footer.toggle.hide': 'Hide',
  'slideshow.fit.label': 'Image Fit',
  'slideshow.fit.fullHeight': 'Full height',
  'slideshow.fit.fullHeight.desc': 'Shows the image entirely in the slideshow',
  'slideshow.fit.scaleToFix': 'Scale to fit',
  'slideshow.fit.scaleToFix.desc': 'Scales image to entire viewport',
  'slideshow.button.addPhotos': 'Add Photos',
  'slideshow.button.removeAll': 'Remove All',
  'slideshow.button.save': 'Save',
  'slideshow.removeAll.text': 'Are you sure you want to delete all photos?',
  'slideshow.removeAll.error.header': 'Error',
  'slideshow.removeAll.error.ok': 'Ok',
  'album.item.label.addText': 'Add Text',
  'album.item.label.coverImageTip': 'Simply set another image as album cover',
  'album.label.addPhotos': 'Add Photos',
  'album.label.addVideo': 'Add Video',
  'album.label.addText': 'Add Text',
  'album.label.move': 'Move',
  'album.item.field.text': 'Text',
  'album.item.placeholder.text': 'Max 1000 characters',
  'album.button.save': 'Save',
  'page.create.menu.title': 'Create New Page',
  'page.create.menu.type.photo': 'Album Collection Page',
  'page.create.menu.type.text': 'Freestyle Page',
  'page.create.menu.type.contact': 'Contact Page',
  'page.create.menu.type.link': 'External Link',
  'page.create.menu.type.simple': 'Simple Page',
  'page.confirmDelete.header': 'Delete {name}',
  'page.confirmDelete.subMessage': 'Are you sure you want to delete {name}?',
  'page.confirmDelete.yesMessage': 'Yes, Delete Page',
  'template.header.main': 'Choose your template',
  'template.header.description':
    'Choose one of our best-in-class website templates and customize it to fit your needs. Making a beautiful website has never been faster.',
  'template.selectedTemplate': 'Selected Template',
  'header.arrowBack.text': 'Return to editing',
  'header.arrowBackToIntegrations.text': 'Back to Integrations',
  'header.devices.desktopLarge.tooltip.title': '{px}px and up',
  'header.devices.desktopLarge.tooltip.text': 'Preview for screen with {px}px and more',
  'header.devices.desktop.tooltip.title': '{px}px and up',
  'header.devices.desktop.tooltip.text': 'Preview for screen with {px}px and more',
  'header.devices.laptop.tooltip.title': '{px}px and up',
  'header.devices.laptop.tooltip.text': 'Preview for screen with {px}px and more',
  'header.devices.tablet.tooltip.title': '{px}px and up',
  'header.devices.tablet.tooltip.text': 'Preview for screen with {px}px and more',
  'header.devices.phone.tooltip.title': '{px}px and up',
  'header.devices.phone.tooltip.text': 'Preview for screen with {px}px and more',
  'header.devices.browser.tooltip.title': 'Open in new tab',
  'header.devices.browser.tooltip.text': 'View website in new tab',
  'user.menu.accountAndBilling': 'Account & Billing',
  'user.menu.domainAndWebsite': 'Domain & Website',
  'user.menu.googleSeo': 'Google(SEO)',
  'user.menu.services': 'Services',
  'user.menu.language': 'Language',
  'user.menu.logout': 'Log Out',
  'upload.video.text':
    'You can embed a background video from Vimeo or Youtube in your slideshow. Background videos play on a loop without sound.',
  'upload.video.label.paste': 'Paste Vimeo or Youtube Link',
  'upload.video.label.remove': 'Remove Video',
  'upload.video.label.add': 'Add Video',
  'upload.video.title': 'Embed video',
  'upload.video.label': 'Paste Vimeo or Youtube Link',
  'upload.video.automaticPreview': 'Automatic Preview',
  'upload.video.customPreview': 'Use Custom Preview Image',
  'upload.video.button.save': 'Save',
  'upload.video.text.title': 'Drag an image here',
  'upload.video.drop.multiple.error': "Can't load multiple images. Add one image at a time.",
  'upload.video.text.browse': 'or <a style="color: #58C367;">browse</a> to select from a folder',
  'upload.video.text.info':
    'For optimal quality upload images with at least 2200 px.<br/>JPEG, JPG, PNG and GIF are supported, up to 10MB.',
  'upload.video.text.info2': 'PNG, JPEG, SVG format is supported, up to 5MB.',
  'upload.photos.title': '{count} files selected',
  'upload.photos.button.removeAll': 'Remove all',
  'upload.photos.button.upload': 'Upload',
  'upload.photos.button.viewFiles': 'View files',
  'upload.photos.beforeUnloadMessage': "You'll lose your data!",
  'upload.photos.orderStart': 'Place Images at start',
  'upload.photos.orderEnd': 'Place Images at end',
  'newDesign.page.create.header.photo': 'Create new Album Collection Page',
  'newDesign.page.create.header.simple': 'Create new Simple Page',
  'newDesign.page.create.header.text': 'Create Freestyle Page',
  'newDesign.page.create.header.url': 'URL Page create new',
  'newDesign.page.create.header.contact': 'Contact Page create new',
  'newDesign.page.create.submit': 'Save',
  'newDesign.page.create.field.title': 'Page Title',
  'newDesign.page.create.field.linkUrl': 'Link url',
  'newDesign.page.create.field.pagePosition': 'Page position',
  'newDesign.page.create.field.mainNavigation': 'Header',
  'newDesign.page.create.field.footerNavigation': 'Footer',
  'newDesign.page.create.field.enablePage.title': 'Enable page',
  'newDesign.page.create.field.enablePage.desc':
    'Disabled pages can’t be accessed by your site visitors',
  'newDesign.page.create.field.displayMode.title': 'Display mode',
  'newDesign.page.create.field.displayMode.desc':
    "In the 'expanded' view mode, all images of the album are shown at once, one after another. The viewer can see all photos of the collection at a glance as miniatures. That is helpful if you have a smaller portfolio or don't want one image to represent the entire album.",
  'newDesign.page.create.field.displayMode.stacked': 'Stacked',
  'newDesign.page.create.field.displayMode.expanded': 'Expanded',
  'newDesign.page.create.field.displayMode.album': 'Entire Album on click',
  'newDesign.page.create.field.displayMode.zoom': 'Zoom image on click',
  'newDesign.page.create.field.displayMode.albumTitle': 'Album Title',
  'newDesign.page.create.field.landingPage': 'Set as landing page',
  'newDesign.page.create.field.hidePage.title': 'Hide page title in the navigation',
  'newDesign.page.create.field.hidePage.desc':
    'But show contents. This is useful if you like to promote albums from a different categories on the start page',
  'newDesign.page.create.field.password.title': 'Password',
  'newDesign.page.create.field.password.desc': 'Enter password to make category protected',
  'newDesign.page.update.header.photo': 'Album Collection Page Settings',
  'newDesign.page.update.header.simple': 'Simple Page Settings',
  'newDesign.page.update.header.text': 'Freestyle Page settings',
  'newDesign.page.update.header.url': 'URL Page settings',
  'newDesign.page.update.header.contact': 'Contact Page settings',
  'newDesign.page.update.submit': 'Save',
  'newDesign.page.update.field.title': 'Page Name',
  'newDesign.page.update.field.subtitle': 'Page Title',
  'newDesign.page.update.field.description': 'Page Description',
  'newDesign.page.update.field.slug': 'Slug',
  'newDesign.page.update.field.linkUrl': 'Link url',
  'newDesign.page.update.field.language': 'Contact form language',
  'newDesign.page.update.button.uploadImage': 'Upload Image',
  'newDesign.page.update.field.selectPhoto': 'Profile Picture',
  'newDesign.page.update.button.change': 'Change',
  'newDesign.page.update.button.delete': 'Delete',
  'newDesign.page.update.field.additionalText': 'Additional Text',
  'newDesign.page.update.field.pagePosition': 'Page position',
  'newDesign.page.update.field.mainNavigation': 'Header',
  'newDesign.page.update.field.footerNavigation': 'Footer',
  'newDesign.page.update.field.enablePage.title': 'Enable page',
  'newDesign.page.update.field.enablePage.desc':
    'Disabled pages can’t be accessed by your site visitors',
  'newDesign.page.update.field.displayMode.title': 'Display mode',
  'newDesign.page.update.field.displayMode.desc':
    "In the 'expanded' view mode, all images of the album are shown at once, one after another. The viewer can see all photos of the collection at a glance as miniatures. That is helpful if you have a smaller portfolio or don't want one image to represent the entire album.",
  'newDesign.page.update.field.displayMode.stacked': 'Stacked',
  'newDesign.page.update.field.displayMode.expanded': 'Expanded',
  'newDesign.page.update.field.displayMode.album': 'Entire Album on click',
  'newDesign.page.update.field.displayMode.zoom': 'Zoom image on click',
  'newDesign.page.update.field.displayMode.albumTitle': 'Album Title',
  'newDesign.page.update.field.landingPage': 'Set as landing page',
  'newDesign.page.update.field.hidePage.title': 'Hide page title in the navigation',
  'newDesign.page.update.field.hidePage.desc':
    'But show contents. This is useful if you like to promote albums from a different categories on the start page',
  'newDesign.page.update.field.password.title': 'Password',
  'newDesign.page.update.field.password.desc': 'Enter password to make category protected',
  'newDesign.page.update.button.deletePage': 'Delete Page',
  'newDesign.page.update.field.seo.metaTitle': 'Page Meta Title',
  'newDesign.page.update.field.seo.description': 'Description',
  'newDesign.page.update.openGraph.upload.title': 'Open Graph Preview Image',
  'newDesign.page.update.openGraph.upload.desc':
    'By default, the first image on the page is used for the badge. You can upload your own image.',
  'newDesign.page.update.contacts.field.altEmail.label': 'Alternative E-mail',
  'newDesign.page.update.contacts.upload.modal.title': 'Profile Picture Upload',
  'newDesign.page.update.contacts.field.altEmail.desc':
    'Email address must be validated to be used as "send from". Otherwise the account email will be used.',
  'newDesign.page.update.contacts.uploadImg.desc': 'Upload your photo or other image here',
  'newDesign.page.update.drop.image': 'Drop an image here',
  'button.uploadImage.title': 'Upload Image',
  'modalConfirmDelete.header': 'Confirm delete',
  'modalConfirmDelete.subMessage': 'Are you sure you want to delete this element?',
  'modalConfirmDelete.cancel': 'Cancel',
  'modalConfirmDelete.yes': 'Yes, Delete',
  'banner.trial.expires': 'Your Vsble MAX trial ends in <span>{date} days.<span/> ',
  'banner.trial.expires.link': 'Upgrade now to keep all advanced professional features.',
  'banner.trial.upgrade': 'Choose plan',
  'banner.trial.delete': 'Delete demo photos',
  'banner.trial.upload.count': 'Uploads used, Upgrade for unlimited uploads',
  'newDesign.album.create.header': 'Create new album',
  'newDesign.album.create.submit': 'Save',
  'newDesign.album.create.field.name': 'Album Title',
  'newDesign.album.create.field.desc': 'Album Description',
  'newDesign.album.create.toggle.visibility.title': 'Hide Album',
  'newDesign.album.create.toggle.visibility.desc':
    'Hidden album can’t be accessed by your site visitors',
  'newDesign.album.create.toggle.hideHeader.title': 'Hide album title in the navigation',
  'newDesign.album.update.header': 'Album settings',
  'newDesign.album.customSlug.error': 'For URL slugs use only latin characters, numbers, - and _',
  'newDesign.album.update.submit': 'Save',
  'newDesign.album.update.field.name': 'Album Title',
  'newDesign.album.update.field.slug': 'Slug',
  'newDesign.album.update.field.desc': 'Album Description',
  'newDesign.album.update.field.password.desc': 'Enter password to make category protected',
  'newDesign.album.update.field.password.title': 'Password',
  'newDesign.album.update.upload.cover.title': 'Custom Cover',
  'newDesign.album.update.upload.cover.desc':
    'You can choose the cover image in the album or upload your own image here.',
  'newDesign.album.update.tabs.seo': 'SEO Settings',
  'newDesign.album.update.tabs.main': 'Main Settings',

  'newDesign.album.update.field.displayCategory': 'Display Album on Category Pages',
  'newDesign.album.update.toggle.visibility.title': 'Hide Album',
  'newDesign.album.update.toggle.visibility.desc':
    'Hidden album can’t be accessed by your site visitors',
  'newDesign.album.update.toggle.hideHeader.title': 'Hide album title in the navigation',
  'newDesign.album.update.toggle.hideHeader.desc':
    'Activate the switch to hide album titles in the overview page.',
  'newDesign.album.update.button.deleteAlbum': 'Delete Album',

  'newDesign.page.update.header.title': 'Category Page Settings',
  'newDesign.page.update.tabs.main': 'Main Settings',
  'newDesign.page.update.tabs.seo': 'SEO Settings',
  'newDesign.page.update.tabs.displayMode': 'Display Mode',
  'newDesign.modal.buttons.save': 'Save Changes',
  'newDesign.modal.buttons.cancel': 'Cancel',

  'discardChanges.subMessage': 'You have made changes. Are you sure you want to discard them?',
  'discardChanges.yesMessage': 'Discard',
  'pdfTool.header': 'PDF tool',
  'pdfTool.button.save': 'Save',
  'pdfTool.enable': 'Enable PDF tool',
  'pdfTool.upload.logo': 'Drag and drop your logo',
  'inputs.slider.value.original': 'Original',
  'pdf.freeUser.title': 'PDF Tool available only<br/>with PRO plan',
  'pdf.freeUser.text': 'Create PDF with your images and send <br/>or download it',
  'pdf.freeUser.upgrade': 'Upgrade Now',
  'statistics.newDesign.header': 'Statistics dashboard',
  'statistics.newDesign.total.visitors': 'visitors on your site now',
  'statistics.newDesign.mostViewed.expand': 'Expand list',
  'statistics.newDesign.referrals.visits': 'Visits',
  'statistics.newDesign.periodPicker.date': 'Date: {date}',
  'statistics.newDesign.subscription.title': 'Statistics available only<br/>with PRO plan',

  'accountBilling.header': 'Account & Billing',
  'accountBilling.account.title': 'Account',
  'accountBilling.billing.title': 'Billing',
  'accountBilling.affiliate': 'Earn <b>30%</b> recurring commission with VSBLE affiliate program',
  'accountBilling.login.header': 'Log in information',
  'accountBilling.login.subheading': 'Here you can change your login information',
  'accountBilling.billing.header': 'Your subscription',
  'accountBilling.billing.subHeading': 'Here you can see and manage your current subscription plan.',
  'accountBilling.billing.subHeadingMobile': 'Manage your current subscription plan.',
  'accountBilling.billing.status.active': 'Active',
  'accountBilling.billing.status.expired': 'Expired',
  'accountBilling.billing.status.text.expires': 'Expires',
  'accountBilling.billing.status.unpaid': 'Unpaid',
  'accountBilling.billing.status.cancelled': 'Cancelled',
  'accountBilling.billing.status.pastDue': 'Past Due',
  'accountBilling.billing.expired': 'Expired. Not active',
  'accountBilling.billing.neverExpires': 'Expires in never',
  'accountBilling.billing.comparePlans': 'Compare plans',
  'accountBilling.billing.upgrade': 'Upgrade',
  'accountBilling.billing.manage': 'Manage subscription',
  'accountBilling.billing.expiredButton': 'Select a plan',
  'accountBilling.billing.downgrade': 'Downgrade to Free plan',
  'accountBilling.billing.paymentInterval': 'Payment interval',
  'accountBilling.billing.renewsOn': 'Renews on',
  'accountBilling.billing.cancels': 'Cancels',
  'accountBilling.billing.expiresOn': 'Expires on',
  'accountBilling.billing.expiredOn': 'Expired on',
  'accountBilling.billing.try.trial': 'Explore All Features Free for 14 Days',


  'accountBilling.billing.alert.disabled': 'Account disabled: Your account has been disabled due to the unsuccessful payment attempts over the past 14 days. To reactivate your account, please update your payment method by clicking "Manage subscription". Thank you for your prompt attention to this matter.',
  'accountBilling.billing.alert.canceled': 'Your subscription is cancelled and will not renew.',
  'accountBilling.billing.alert.keep.subscribed': 'Keep subscribed',
  'accountBilling.billing.alert.pastDue': 'Payment Error Alert: Your payment attempt was unsuccessful. Please verify or update your payment method by clicking on “Manage Subscription”. We will retry for 14 days. Without a successful payment, your subscription will be cancelled. Thank you for your immediate attention.',
  'accountBilling.billing.alert.expired': 'Your subscription is cancelled and will not renew.',


  'accountBilling.billing.plan.proMax14Days': 'VSBLE MAX - 14 Days Free Trial',
  'accountBilling.billing.plan.proMax': 'VSBLE MAX',
  'accountBilling.billing.plan.pro': 'VSBLE PRO',
  'accountBilling.billing.plan.lifetimeFree': 'VSBLE LIFETIME PRO',
  'accountBilling.billing.plan.proLite': 'VSBLE START',
  'accountBilling.billing.plan.free': 'Lifetime free plan',

  'accountBilling.login.field.email': 'E-mail',
  'accountBilling.login.field.changePassword': 'Password',
  'accountBilling.login.button.update': 'Update',
  'accountBilling.login.button.changePassword': 'Change',
  'accountBilling.address.header': 'Billing address',
  'accountBilling.address.field.name': 'Name',
  'accountBilling.address.field.address1': 'Address Line 1',
  'accountBilling.address.field.address2': 'Address Line 2',
  'accountBilling.address.field.city': 'City',
  'accountBilling.address.field.zipCode': 'ZIP Code',
  'accountBilling.address.field.country': 'Country',
  'accountBilling.address.button.update': 'Save billing info',
  'accountBilling.error.requiredFields': 'All fields are required',
  'accountBilling.tabs.account': 'Account',
  'accountBilling.tabs.address': 'Billing address',
  'accountBilling.tabs.history': 'Billing history',
  'vatValidator.header': 'Have a VAT ID? If not, leave empty',
  'vatValidator.error': 'Code is not valid',
  'vatValidator.button.apply': 'Apply',
  'deleteAccount.header': 'Delete account',
  'deleteAccount.text': 'Permanently delete this account?',
  'deleteAccount.button.text': 'Delete Account',
  'deleteAccount.modal.header': 'Delete account',
  'deleteAccount.modal.text':
    'Once you confirm, all of your account data and your website will be permanently deleted',
  'deleteAccount.modal.field.email': 'Enter your account e-mail',
  'deleteAccount.modal.button.yes': 'Yes, Delete Account',
  'deleteAccount.modal.button.cancel': 'Cancel',
  'deleteAccount.modal.error.email': 'Incorrect e-mail',
  'deleteAccount.modal.error.email.required': 'E-mail is required',
  'billingHistory.header': 'Billing History',
  'billingHistory.noItems.message': 'You haven\'t made any purchases yet.',
  'billingHistory.button.viewInvoices': "View invoices",

  'billingHistory.status.paid': 'Paid',
  'billingHistory.status.failed': 'Failed',
  'billingHistory.status.pending': 'Pending',
  'billingHistory.column.type.pro':
    'VSBLE Pro, {currency, select, eur {€} usd {$} other {€}}{amount}',
  'billingHistory.column.type.prolite':
    'VSBLE Starter, {currency, select, eur {€} usd {$} other {€}}{amount}',
  'billingHistory.column.link.pay.now': 'Pay now',
  'billingHistory.column.link.download': 'Download',
  'subscription.capabilities.free': formatMessagesList([
    'Upload Limit: 50 Images',
    'Unlimited Video Uploads',
    'Unlimited Bandwidth Usage',
    'Selection of 4 Templates',
    'Mobile-Friendly Version',
    'Free Web Address',
    'Included SSL Certificate',
    'Free Cookie Solution',
    'GDPR Compliance',
  ]),
  'subscription.capabilities.light': formatMessagesList([
    "<span style='font-weight: bold'>All from Free +</span>",
    'Upload Limit: 250 Images',
    'Custom Domain Connection',
    'SSL Security for Your Domain',
    'Basic SEO Features',
    'Custom Logo Upload Option',
    'Freestyle Editor for Pages',
    'Customizable Website Favicon',
    'Support for GIFs',
    'PDF Selection Tool',
    'Visitor Analytics',
    'Minimal Branding',
  ]),
  'subscription.capabilities.pro': formatMessagesList([
    "<span style='font-weight: bold'>All from Start +</span>",
    'Unlimited Uploads',
    'All Templates',
    'Customize Colors & Fonts',
    'Advanced SEO',
    'Open Graph Image',
    'Page Passwords',
    'Custom Video Placeholders',
    'Premium Support',
    'Remove Branding',
  ]),
  'subscription.capabilities.promax': formatMessagesList([
    "<span style='font-weight: bold'>All from PRO +</span>",
    'Custom integrations',
    'Advanced SEO',
    'Ai Crawler Block',
    'SEO for Albums & Pages',
    'AI Image Alt-Text',
    "Image Editing tools",
    'Widgets ex. age verification',
    'Custom Forms Integrations',
    "Add Chat, Blog, Booking",
    "Connect Newsletter Tools",
    "Shop Integrations (e.g., Etsy)",
    "Google Search Console",
    "Google Analytics & Tags",
    "Facebook Pixel",
    "Priority support"
  ]),
  'subscription.modal.title.upgrade': 'Upgrade',
  'subscription.modal.title.trialEnded':
    "Your trial <span style='color:#58C367'>Vsble MAX</span> has ended",
  'subscription.modal.step.1.title': '1. Your Billing Address',
  'subscription.modal.step.2.title': 'Select Plan & Billing Cycle',
  'subscription.modal.step.2.title.mobile': 'Select Plan',
  'subscription.modal.step.3.title.mobile': 'Billing Cycle',
  'subscription.modal.plan.price.label.month': 'Monthly Payment',
  'subscription.modal.plan.price.label.year': 'Yearly Payment',
  'subscription.modal.plan.price.title': `<span>${shared.currency}{price}/</span> mo`,
  'subscription.modal.plan.price.subTitle.month': 'billed monthly',
  'subscription.modal.plan.price.subTitle.year': 'billed yearly',
  'subscription.modal.plan.price.savedMoneyInfo': `Save ${shared.currency}{savedForYear}`,
  'subscription.modal.plan.total.title': 'Total',
  'subscription.modal.plan.total.price': `${shared.currency}{totalPrice}`,
  'subscription.modal.plan.endDate': '{planType} until {date}',
  'subscription.modal.plan.autoRenew': 'Will auto-renew until cancelled',
  'subscription.modal.plan.button.payByCard.desc':
    'The amount will be automatically charged until you cancel',
  'subscription.modal.plan.button.payByCard.text': 'Continue to payment',
  'subscription.modal.plan.button.payByInvoice.desc':
    'Pay on each renewal periods manually with various payment methods.',
  'subscription.modal.plan.button.payByInvoice.text': 'Pay by Invoice',
  'subscription.plan.price.title': `${shared.currency}{price} <span style='font-size:17px'>/mo.</span>`,
  'subscription.plan.price.subtitle': `${shared.currency}{price} <span style='font-size:12px'>/mo.</span>*`,
  'subscription.plan.others.price.subTitle': `or ${shared.currency}{price} / mo. <br/> with yearly payment`,
  'subscription.plan.button.manageSub': 'Manage Subscription',
  'subscription.plan.free.price.subTitle': 'Forever <br/> FREE',
  'subscription.plan.free.header': 'FREE',
  'subscription.plan.free.limit': 'Max. 50 images',
  'subscription.plan.free.domain': 'Free Vsble.me domain',
  'subscription.plan.trial.header': 'Free trial',
  'subscription.plan.trial.daysLeft': 'Your free trial ends on',
  'subscription.plan.light.header': 'Start',
  'subscription.plan.light.domain': '',
  'subscription.plan.light.button.upgrade': 'Select Start',
  'subscription.plan.pro.header': 'Pro',
  'subscription.plan.pro.limit': 'Unlimited everything',
  'subscription.plan.pro.domain': 'Connect your domain',
  'subscription.plan.pro.statistics': 'Website Statistics',
  'subscription.plan.pro.button.upgrade': 'Select PRO',
  'subscription.plan.promax.header': 'Max',
  'subscription.plan.promax.button.upgrade': 'Select MAX',
  'subscription.plan.billingOption.header': 'Your billing option',
  'subscription.plan.billingOption.pay':
    'Pay {interval, select, year {Annually} month {Monthly} other {Daily}}',
  'subscription.plan.billingOption.interval':
    '{interval, select, year {year} month {month} other {day}} billed {interval, select, year {yearly} month {monthly} other {daily}}',
  'subscription.plan.billingOption.renew': 'Auto-renew is {renew, select, on {on} other {off}}',
  'subscription.plan.billingOption.renewDate': 'Subscription renews on',
  'subscription.plan.billingOption.cancelDate': 'Current plan ends on',
  'trialUpgrade.header': 'Upgrade to VSBLE Pro or VSBLE MAX',
  'trialUpgrade.button': 'Upgrade',
  'startTrial.header': 'Try VSBLE Pro MAX for FREE for 14 Days',
  'startTrial.text': 'No credit card needed. You can revert to free anytime.',
  'startTrial.button': 'Upgrade',
  'startTrial.modal.header': 'Try VSBLE Pro MAX for FREE for 14 Days',
  'startTrial.modal.subMessage': 'Start your 14 days Pro MAX trial. No credit card needed.',
  'startTrial.modal.button.start': 'Try VSBLE Pro MAX for FREE',
  'startTrial.modal.button.skip': 'Skip Trial, upgrade',
  'startTrial.modal.welcome.header': 'Welcome to 14 days Vsble MAX trial',
  'startTrial.modal.welcome.text':
    'You can use Vsble MAX for 14 days for free — no need to enter a credit card!',
  'startTrial.modal.welcome.button': 'OK',
  'removeBranding.header': 'Remove branding',
  'removeBranding.text': 'Remove Vsble logo from website footer',
  'downgradeToFree.header': 'Downgrade to FREE',
  'downgradeToFree.modal.button.confirm': 'Confirm downgrade',
  'cards.pay': 'Pay with card',
  'cards.info': '{brand} ending in {lastNums} expiring {expMonth}/{expYear}',
  'cards.manage.button': 'Manage Card',
  'proUpgrade.header': 'VSBLE Pro/Pro MAX upgrade',
  'proUpgrade.step': 'Step {step}',
  'proUpgrade.plans.header': 'Please select your billing cycle',
  'proUpgrade.plans.desc':
    'You can cancel anytime. If you cancel, your plan will expire at the end of the billing cycle, and not auto-renew.',
  'proUpgrade.plan.price': '{currency, select, eur {€} usd {$} other {€}}{price}',
  'proUpgrade.plan.mo': '/mo.',
  'proUpgrade.plan.billed':
    '{price}{currency, select, eur {€} usd {$} other {€}} billed {interval, select, year {yearly} month {monthly} other {daily}}',
  'proUpgrade.plan.button.continue': 'Continue to billing info',
  'proUpgrade.billingDetails.header': 'Check or update your billing details',
  'proUpgrade.billingDetails.desc':
    'Check your billing information. This information will appear on your invoice.',
  'proUpgrade.billingDetails.button.continue': 'Continue to payment',
  'proUpgrade.billingDetails.button.back': 'Back',
  'trialEnded.header': 'Your Trial VSBLE Pro MAX has ended',
  'trialEnded.header.prices':
    'Upgrade to VSBLE Pro MAX, {currency, select, eur {€} usd {$} other {€}}{monthPrice}/month or {currency, select, eur {€} usd {$} other {€}}{yearPrice}/year',
  'trialEnded.header.features': 'Unlock unlimited image uploads, video embeds and custom domain',
  'trialEnded.plans.desc':
    'Cancel anytime and your plan will automatically end on the date of the next due payment.',
  'trialEnded.divider.text': 'Or',
  'trialEnded.invoice.header': 'Pay By Invoice',
  'trialEnded.invoice.text': 'Pay on each renewal periods manually with various payment methods.',
  'trialEnded.invoice.button': 'Pay By Invoice',
  'trialEnded.downgrade.header':
    'Downgrade to Free, {currency, select, eur {€} usd {$} other {€}}0',
  'trialEnded.downgrade.images': 'Max. 50 images',
  'trialEnded.downgrade.domain': 'Free Vsble.me domain',
  'trialEnded.downgrade.button': 'Downgrade',
  'changePassword.header': 'Change Password',
  'changePassword.field.oldPassword': 'Old Password',
  'changePassword.field.newPassword': 'New Password',
  'changePassword.field.confirmNewPassword': 'Confirm New Password',
  'changePassword.button.forgotPassword': 'Forgot Password?',
  'changePassword.button.submit': 'Change Password',
  'changePassword.button.cancel': 'Cancel',
  'changePassword.error.required': 'All fields are required',
  'changePassword.error.password.match': 'Confirmed password does not match',
  'changePassword.status.success': 'Your password has been successfully changed',
  'changePassword.sendResetLink.header': 'Forgot your password?',
  'changePassword.sendResetLink.text':
    "Enter your account's e-mail and we'll send you a magic link to reset the password",
  'changePassword.sendResetLink.field.email': 'E-mail',
  'changePassword.sendResetLink.button.submit': 'Send Magic Link',
  'changePassword.sendResetLink.button.back': 'Back',
  'changePassword.sendResetLink.success.header': 'E-mail with the reset link has been sent',
  'changePassword.sendResetLink.success.text': 'Check your inbox (or spam folder)',
  'textEditor.button.save': 'Save changes',
  'textEditor.button.cancel': 'Cancel',
  'seo.browserIcon': 'Browser Icon (Favicon)',
  'seo.browserIcon.description': 'A favicon is a small icon in browser tabs, aiding quick site recognition. It boosts user experience and branding. For search engines, it enhances site visibility in search results, potentially increasing clicks. The favicon is applied uniformly across the entire website.',
  'seo.browserIcon.files': 'PNG, JPEG, SVG files are accepted',
  'seo.browserIcon.uploadFavicon': 'Upload Favicon',
  'seo.browserIcon.DeleteFavicon': 'Delete Favicon',
  'seo.browserIcon.preview': 'Browser Tab  Preview',

  'seo.uploadImage': 'Open Graph preview image',
  'seo.uploadImage.description1': 'Open Graph helps websites tell social media what their content is about, creating appealing previews when links are shared. This can drive more traffic to your site, which is good for SEO because it signals to search engines that your content is valuable and popular.',
  'seo.uploadImage.description2': 'You have the option to customize Open Graph details for each page and album individually. If not set, the global settings will automatically apply.',
  'seo.button.uploadImage': 'Upload Image',
  'seo.button.deleteImage': 'Delete Image',
  'seo.generateFiles.title': 'Generate Sitemap and Robots.txt Links',
  'seo.enableOpenAiBlock.title': 'Prevent OpenAI from accessing your content',
  'seo.googleVerification.title': 'Google Site Verification',
  'seo.googleVerification.desc': `Verifying your site with Google will give you acces to your website’s private Google Search data.
        You will also be able to affect how Google Search crawled your site.`,
  'seo.googleVerification.input.label': 'Google Site Verification ID',
  'copyToClipboard.title': 'Copy to clipboard',
  'templates.label.vien': 'VIENNA',
  'templates.label.vien.black': 'VIENNA BLACK',
  'templates.label.stockholm': 'STOCKHOLM',
  'templates.label.stockholm.black': 'STOCKHOLM BLACK',
  'tabs.tab.slideshow': 'Slideshow',
  'tabs.tab.logo': 'Logo',
  'tabs.tab.portfolio': 'Portfolio',
  'editLogo.label.or': 'Or Upload Custom Logo',
  'editLogo.label.customLogo': 'Custom Logo',
  'editLogo.text.customLogo': 'Jpg, gif or png files are accepted with max size of 10mb.',
  'editLogo.upload.customLogo': 'Upload Custom Logo',
  'editLogo.label.favicon': 'Browser Icon (Favicon)',
  'editLogo.text.favicon':
    'Png files are accepted. The favicon will appear in the browser next to your domain name.',
  'editLogo.upload.favicon': 'Upload Favicon',
  'editLogo.button.save': 'Save',
  'editLogo.button.remove.background': 'Remove background',
  'editLogo.button.remove.background.done': 'Will be done in 1 minute or less',
  'editLogo.button.change': 'Change',
  'website.settings.less': 'Must be less than 100 characters',
  'website.settings.validation.free':
    'Domain must contain only latin characters and 0-9 numbers in lowercase without dots(.)',
  'website.settings.validation.premium':
    'Domain must contain only latin characters and 0-9 numbers in lowercase without dots(.)',
  'website.settings.validation.premium.vsbleForbidden': 'Vsble.me is forbidden.',
  'website.settings.validation.common': 'Enter valid domain',
  'website.settings.dots': 'Enter a name without "www" or dots.',
  'website.settings.without.dots': 'We are connecting your domain, please wait until you see the connected sign',
  'website.settings.retry': 'Retry',
  'website.settings.remove': 'Remove',
  'website.settings': 'Domain & Website Settings',
  'seo.google': 'Google (SEO)',
  'seo.title.label': 'Website title',
  'seo.title.placeholder': 'VSBLE',
  'seo.description.label': 'Description',
  'seo.description.placeholder': 'Portfolio websites for visual artists.',
  'seo.keywords.label': 'Keywords',
  'seo.keywords.placeholder': 'photography, websites, portfolios, website builder, vsble',
  'seo.save': 'Save',
  'seo.accepted.files1': 'PNG files are accepted',
  'seo.accepted.files2': 'JPG, PNG & GIF files are accepted',
  'seo.search': 'Search Engines Preview',
  'seo.tab': 'Browser Tab Preview',
  'seo.social': 'Social Sites Preview',
  'seo.google.website.settings': 'Global Website settings',
  'seo.google.site.verification': 'Google Site Verification',
  'seo.description.website.settings': "Global settings apply to the whole website, but you can set specific titles and descriptions for each page or album in their settings. If not set, the global settings will be used by default.",
  'services.title': 'Services',

  'services.fiverr.title': 'Find experts to grow your brand',
  'services.fiverr.description':
    'Connect effortlessly with top-selected experts in SEO, marketing, blogging, and social media marketing, all available on Fiverr.',
  'services.elfsight.title': 'Upgrade your website',
  'services.elfsight.description':
    'Over 80 widgets for your site. From Podcast Player, Social Media feed to Before/After sliders, Google Maps and Telegram Chat. Explore the the powerful extensions that you can add without any coding.',
  'services.weglot.title': 'Make your website multilingual',
  'services.weglot.description':
    'Translate your website to over 150 languages automatically, Weglot has everything you need to translate, display and manage your multilingual website, with full editing control. Free plan available.',
  'services.ecwid.title': 'Add a shop to your website',
  'services.ecwid.description':
    'The easiest way to sell anything on your website — products, services, digital goods, subscriptions — without coding, developers, designers. Free plan available.',
  'services.jotform.title': 'Powerful form builder',
  'services.jotform.description':
    'Go from busywork to less work with powerful contact & order forms. Apply conditional logic, accept payments, generate reports, and automate workflows.',
  'uploadFile.error.fileSize.description':
    'Your file exceeds the upload limit, please upload file smaller than {size}mb.',
  'uploadFile.error.fileType.description': 'Accepted {types} files only.',
  'pdfTool.upload.text.info':
    'For optimal quality upload images with at least 2200 px.<br/>JPEG, JPG, and PNG are supported, up to 10MB.',
  'uploadFile.error.header': 'Upload error',
  'uploadFile.error.ok': 'Ok',
  'current.theme': 'Current Theme',
  'templates.label.oslo': 'OSLO',
  'templates.label.oslo.black': 'OSLO BLACK',
  'templates.label.rotterdam': 'ROTTERDAM',
  'templates.label.rotterdam.black': 'ROTTERDAM BLACK',
  'templates.label.losangeles': 'LOS ANGELES',
  'templates.label.losangeles.black': 'LOS ANGELES BLACK',
  'styles.title.albumPage.note': 'Image description',
  'page.field.error.url': 'Link url cannot be empty',
  'album.label.view.expand': 'Expand view',
  'album.label.view.shrink': 'Shrink view',
  'page.create.menu.type.html': 'Widget / HTML / Blog',
  'page.create.menu.type.ecwid': 'Shop',
  'user.menu.integrations': 'Integrations',
  'integration.name': 'Integrations',
  'integration.soon': 'Soon',
  'integration.edit.settings': 'Edit settings',
  'integration.ecwid.name': 'Ecwid Integration',
  'integration.ecwid.slogan': 'Everything you need to sell online',
  'integration.start.free': 'Start for free today',
  'integration.footer.code': 'Footer Page code',
  'integration.ecwid.code.here': '$> Ecwid code here',
  'integration.save': 'Save changes',
  'integration.ecwid.join':
    'Join hundreds of thousands of small businesses who trust Ecwid E-commerce to sell online.',
  'integration.ecwid.setup':
    'Set up your Ecwid store once to easily sync and sell across a website, social media, marketplaces like Amazon, and live in-person.',
  'integration.ecwid.forever.free':
    'With Ecwid, you get free FOREVER. Set up your free account once, and keep it as long as you like. Seriously.',
  'integration.ecwid.add': 'Add the Ecwid integration code to VSBLE',
  'integration.ecwid.note.1':
    "Log into your Ecwid account. If you don’t have one yet, create a free account <a href='http://open.ecwid.com/3rq3FR' target='_blank'>here.</a>",
  'integration.ecwid.note.2': 'In your Ecwid admin, click on “website” in the left panel.',
  'integration.ecwid.note.3':
    'Scroll down to the "Click “Add your store to your website.” on the presented screen, scroll down to Squarespace and select it.',
  'integration.ecwid.note.4':
    'Copy the long code under 2nd point and paste it in your Vsble. Ecwid integration in footer page code area (on the left of this screen).',
  'integration.ecwid.note.5':
    'Create a shop page on your Vsble website and copy the short code from the 1st point into the code area of that page.',
  'integration.ecwid.video': 'Watch video tutorial',
  'integration.ecwid.tips': 'Tips:',
  'integration.ecwid.help.1': 'Selling digital products',
  'integration.ecwid.help.2': 'Selling services',
  'integration.ecwid.help.3': 'Selling print on-demand',
  'integration.ecwid.help.4': 'Selling physical products',
  'integration.ecwid.help.5': 'Ecwid help center',
  'integration.watch.tutorial': 'Watch video tutorial',
  'integration.calendly.header': 'Calendly Integration',
  'integrations.calendly.sample.header': 'https://calendly.com/youurl',
  'integration.calendly.teaser':
    'Calendly is your hub for scheduling meetings professionally and efficiently.',
  'integration.calendly.note.header': 'Add Calendly to Vsble.',
  'integration.calendly.step.1': '1',
  'integration.calendly.step.1.text': 'Create a new  HTML page.',
  'integration.calendly.step.2': '2',
  'integration.calendly.step.2.text':
    'Paste following code and replace the red portion between “ ” with your Calendly calendar page URL.',
  'integration.calendly.step.3': '3',
  'integration.calendly.step.3.text': 'Name your page (Ex. Booking) and save.',
  'integration.typeform.header': 'Typeform Integration',
  'integrations.typeform.sample.header': 'REPLACE THIS TEXT WITH YOUR LINK',
  'integration.typeform.teaser': 'Create forms, surveys, and quizzes that people enjoy answering.',
  'integration.typeform.note.header': 'Add Typeform to Vsble.',
  'integration.typeform.step.1': '1',
  'integration.typeform.step.1.text': 'Create a new HTML page.',
  'integration.typeform.step.2': '2',
  'integration.typeform.step.2.text':
    'Copy and paste the following code int the HTML Page Code window.',
  'integration.typeform.step.3': '3',
  'integration.typeform.step.3.text': 'Replace the red text with your Jotform form URL.',
  'integration.jotform.header': 'Jotform Integration',
  'integration.jotform.note.header': 'Add the Blogger integration code to Vsble.',
  'integration.jotform.teaser':
    'Powerful forms that use conditional logic, accept payments, generate reports, and automate workflows.',
  'integration.jotform.step.1': '1',
  'integration.jotform.step.1.text': 'Create a new HTML page.',
  'integration.jotform.step.2': '2',
  'integration.jotform.step.2.text':
    'Copy and paste the following code int the HTML Page Code window.',
  'integrations.jotform.sample.header': 'REPLACE THIS TEXT WITH YOUR LINK',
  'integration.jotform.step.3': '3',
  'integration.jotform.step.3.text': 'Replace the red text with your Jotform form URL.',
  'newDesign.page.create.header.html': 'Create new html page',
  'newDesign.page.create.header.ecwid': 'Create Ecwid Shop Page',
  'newDesign.page.create.ecwid.link': 'Ecwid Settings',
  'newDesign.page.create.ecwid.page.code': 'Ecwid Page Code',
  'newDesign.page.create.page.code': 'Page Code',
  'newDesign.page.update.header.html': 'HTML Page Settings',
  'newDesign.page.update.header.ecwid': 'Ecwid shop Page Settings',
  'billing.interval.monthly': 'Monthly',
  'billing.interval.monthly.renews.info': 'Renews monthly until cancelled',
  'billing.interval.yearly.renews.info': 'Renews yearly until cancelled',
  'billing.interval.yearly': 'Yearly',
  'billing.free.upgrade.features':
    'Unlock all professional features, such as all templates, unlimited uploads, custom domain connection, shop and extension integrations.',
  'billing.trial.upgrade.features':
    'Unlock professional features & unlimited uploads starting at 6€/month',
  'billings.plans.label.saveMoneyInfo':
    "Save {savedForYear}€ ( {savedForYearPercent}% off ). It's like getting {savedMonths} months free!",
  'billingHistory.column.type.promax':
    'VSBLE Pro MAX, {currency, select, eur {€} usd {$} other {€}}{amount}',
  'permission.modal.pro.subMessage':
    'This feature is included in our higher tier subscription. To access this feature, please upgrade your subscription.',
  'permission.modal.pro.max.header': 'Unlock more features!',
  'permission.modal.pro.max.subMessage':
    'This feature isn’t included in your current plan. Upgrade to gain access to this and many more amazing features!',
  'permission.modal.buttonMessage': 'Compare plans & features',
  'focusPoint.modal.message':
    'Move the focal point to determine which part of the image stays focused in the frame.',
  'focusPoint.modal.button.ok': 'Save',
  'addInfoAndAlt.modal.button.save': 'Save',
  'addInfoAndAlt.modal.button.descriptive': 'Descriptive',
  'addInfoAndAlt.modal.button.decorative': 'Decorative',
  'addInfoAndAlt.modal.textarea.placeholder': 'Sunset over New York',
  'addInfoAndAlt.modal.title': 'Info & Alt Text',
  'addInfoAndAlt.modal.alt.subtitle': 'ALT-text',
  'addInfoAndAlt.modal.decorative.text':
    'Decorative images don’t convey meaning and don’t require alt text',
  'addInfoAndAlt.modal.descriptive.text':
    'ALT text is a short description added to an image to provide additional information about it. It is important for both SEO and accessibility. For SEO, it helps search engines understand the content of the image and its relevance to the surrounding text, which can improve its visibility in search results. It gives also screen readers info about the picture for people who can’t see it.',
  'album.label.focusPoint': 'Focus point',
  'album.label.cropper': 'Crop and Resize',
  'album.label.info': 'Info & Alt Text',
  'album.label.replace': 'Replace',
  'cropper.settings.free': 'Free',
  'cropper.settings.fixed': 'Fixed',
  'cropper.button.save': 'Save Changes',
  'cropper.button.cancel': 'Cancel',
  'integrations.tawk.header': 'Tawk.to Integration',
  'integrations.tawk.step.1': '1',
  'integrations.tawk.step.2': '2',
  'integrations.tawk.step.3': '3',
  'integrations.tawk.teaser':
    'Monitor and chat with the visitors on your website, respond to support tickets and create a help center to empower customers to help themselves – all 100% Free',
  'integrations.tawk.code.here': 'Tawk.to code here',
  'integrations.tawk.note.header': 'Add the Tawk.to integration code to VSBLE',
  'integrations.tawk.note.1':
    'Log into your Tawk.to account. If you don’t have one yet, create a free account',
  'integrations.tawk.note.2':
    'In your Tawk.to dashboard, click on “Administration” icon in the left panel.',
  'integrations.tawk.note.3':
    'Click to “Chat widget“ section, and copy the long code “Widget Code“ and paste it in your Vsble',
  'integrations.livechat.header': 'Livechat Integration',
  'integrations.livechat.teaser':
    'A complete customer service platform that delights your customers',
  'integrations.livechat.code.here': 'Livechat code here',
  'integrations.livechat.note.header': 'Add the Livechat integration code to VSBLE',
  'integrations.livechat.note.1':
    'Log into your Livechat account. If you don’t have one yet, create a free account',
  'integrations.livechat.note.2':
    'In your Livechat account, click on “Settings” icon in the left panel.',
  'integrations.livechat.note.3':
    'Click to “Website“ section, and copy the long code “Install LiveChat code manually“ and paste it in your Vsble',
  'integrations.chatra.header': 'Chatra Integration',
  'integrations.chatra.teaser':
    'Talk with visitors on your website: live chat, chat bots, email & social messaging for business',
  'integrations.chatra.code.here': 'Chatra code here',
  'integrations.chatra.note.header': 'Add the Chatra integration code to VSBLE',
  'integrations.chatra.step.1': '1',
  'integrations.chatra.step.2': '2',
  'integrations.chatra.step.3': '3',
  'integrations.chatra.note.1':
    'Log into your Chatra account. If you don’t have one yet, create a free account',
  'integrations.chatra.note.2':
    'In your Chatra dashboard, click on “Settings” icon in the left panel.',
  'integrations.chatra.note.3':
    'Click to “Chat widget“ section, and copy the long code and paste it in your Vsble',
  'integrations.helpspace.header': 'Helpspace Integration',
  'integrations.helpspace.teaser':
    'A help desk with team inbox, intuitive interface, self-service sites, and multiple inbound channels to get a competitive edge in your market.',
  'integrations.helpspace.code.here': 'Helpspace code here',
  'integrations.helpspace.note.header': 'Add the Helpspace integration code to VSBLE',
  'integrations.helpspace.note.1':
    'Log into your Helpspace account. If you don’t have one yet, create a free account',
  'integrations.helpspace.note.2':
    'In your Helpspace dashboard, click on “Settings” icon in the left panel.',
  'integrations.helpspace.note.3':
    'Click to “Widgets“ section, and copy the long code from “Embed Options“ and paste it in your Vsble',
  'integrations.tidio.header': 'Tidio Integration',
  'integrations.tidio.step.1': '1',
  'integrations.tidio.step.2': '2',
  'integrations.tidio.step.3': '3',
  'integrations.tidio.teaser':
    'Chat with your customers, boost their engagement, and watch your sales go through the roof.',
  'integrations.tidio.code.here': 'Tidio code here',
  'integrations.tidio.note.header': 'Add the Tidio integration code to VSBLE',
  'integrations.tidio.note.1':
    'Log into your Tidio account. If you don’t have one yet, create a free account',
  'integrations.tidio.note.2':
    'In your Tidio dashboard, click on “Settings” icon in the left panel.',
  'integrations.tidio.note.3':
    'Click to “Live chat -> Installation -> Javascript“ section, and copy the long code and paste it in your Vsble',
  'integrations.optinly.header': 'Optinly Integration',
  'integrations.optinly.teaser': 'Build Smart Email Pop-ups',
  'integrations.optinly.code.here': 'Optinly code here',
  'integrations.optinly.note.header': 'Add the Optinly integration code to VSBLE',
  'integrations.optinly.step.1': '1',
  'integrations.optinly.step.2': '2',
  'integrations.optinly.note.1':
    'Log into your Optinly account. If you don’t have one yet, create a free account',
  'integrations.optinly.note.2':
    'In you Optinly dashboard click on “Installation instructions” on top of the screen.',
  'integrations.optinly.note.3':
    'Select “JavaScript”, copy the provided code snippet and paste it in the code window on this page.',
  'integrations.blogger.header': 'Blogger Integration',
  'integrations.blogger.teaser': "Create a unique and beautiful blog. It's easy and free.",
  'integrations.blogger.code.here': 'Blogger code here',
  'integrations.blogger.note.header': 'Add the Blogger integration code to VSBLE',
  'integrations.blogger.step.1': '1',
  'integrations.blogger.step.2': '2',
  'integrations.blogger.note.1': 'Create a new HTML page',
  'integrations.blogger.note.2': 'Copy and paste the following code int the HTML Page Code window.',
  'integrations.blogger.sample.header': 'URL of the website you want to embed',
  'integrations.blogger.sample.text': 'Your browser does not support iFrames.',
  'integrations.blogger.note.3': 'Replace the red text with your blog URL',
  Color: 'Color',
  backgroundColor: 'Background Color',
  logoColor: 'Logo Color',
  sublogoColor: 'Sublogo Color',
  closeIconColor: 'Close Icon Color',
  Font: 'Font',
  'integrations.weglot.header': 'Weglot Integration',
  'integrations.weglot.step.1': '1',
  'integrations.weglot.step.2': '2',
  'integrations.weglot.step.3': '3',
  'integrations.weglot.step.4': '4',
  'integrations.weglot.teaser':
    'Weglot allows you to make your website multilingual in minutes and to manage all your translations effortlessly.',
  'integrations.weglot.code.here': 'Weglot code here',
  'integrations.weglot.note.header': 'Add the Weglot integration code to VSBLE',
  'integrations.weglot.note.1': 'Login to your Weglot account and click on setting, then on setup.',
  'integrations.weglot.note.2': 'Copy the JS code.',
  'integrations.weglot.note.3': 'Add "body" class to dynamic elements.',
  'integrations.weglot.note.4': 'Paste the code in the code section on this page, click save.',
  'header.whats.new': "What's new",
  'styles.title.visiblepro': 'VSBLE Pro',
  'website.settings.visibility.live': 'Website is live',
  'website.settings.visibility.offline': 'Website is offline',
  'subscription.plan.trial.upgrade.now': 'Upgrade now',
  'subscription.plan.trial.title':
    'You have {days} days left in your free trial. Upgrade now to keep using professional features and to optimize your website performance.',
  'subscription.plan.lifetimepro.header': 'Lifetime PRO',
  'subscription.plan.pro.cardError':
    'You recent payment has failed. Please check & update your payment method by clicking the button below',
  'subscription.plan.pro.notice':
    '*Current equivalent in USD, updated daily. Prices will be charged in $ and deducted in the euqivalent of your country currency. The final amount may\n' +
    'vary depending on your bank conversion and applied fees.',
  'subscription.plan.pro.cardError.button': 'Update payment',
  'downgradeToFree.modal.button.changedMind': 'I changed my mind',
  'downgradeToFree.modal.features.header':
    'Your account will downgrade to VSBLE FREE when you hit confirm',
  'downgradeToFree.modal.features.subheader':
    'Your account will be reset to default VSBLE FREE functions. The current template will be reset to one of the default FREE templates Barcelona. Your first uploaded images will remain. Below is a list of key features that you loose and keep with the downgrade.',
  'downgradeToFree.modal.features.loose.header': 'Features you loose',
  'downgradeToFree.modal.features.loose': formatMessagesList([
    '— Unlimited image uploads',
    '— All templates included',
    '— Temlate customization',
    '— Custom favicon',
    '— Extended SEO & Open Graph',
    '— Custom domain connection',
    '— PDF Tool',
    '— Password protection for pages',
    '— Integrations & Shop',
    '— Visitor statistics',
    '— Multilingual contact page',
  ]),
  'downgradeToFree.modal.features.keep.header': 'Features you keep',
  'downgradeToFree.modal.features.keep': formatMessagesList([
    '— 50 Images upload limit',
    '— Vsble.me branded domain',
    '— Free SSL-Certificate',
    '— Unlimited Video embedding',
    '— 4 Templates',
    '— Black & White templates',
    '— Mobile version included',
  ]),
  'downgradeToFree.modal.reasons.header': 'What made you decide to cancel your subscription?',
  'downgradeToFree.modal.reasons': formatMessagesList([
    'I couldn’t use it on my mobile device',
    'It’s missing key features',
    'It’s too expensive',
    'I will upgrade later eventually',
    'I just wasn’t using it enough',
    'It’s too confusing',
    'It’s something else',
  ]),
  'downgradeToFree.modal.message.header':
    'Please describe what’s missing or can be improved in your own words',
  'downgradeToFree.modal.extendTrial.header': 'Extend Pro MAX trial for 10 days for 10€ ?',
  'downgradeToFree.modal.extendTrial.subHeader': 'One-time payment, no subscription',
  'downgradeToFree.modal.extendTrial.description':
    'Keep the progress and continue exploring all the features of Vsble MAX for another 10 days before downgrading to a lifetime FREE account.',
  'downgradeToFree.modal.extendTrial.yes': 'Yes, extend Pro MAX trial',
  'downgradeToFree.modal.extendTrial.no': 'No, downgrade to Free',
  'styles.title.loaderAnimation': 'Loader Animation',
  'styles.loaderAnimation.changeButton': 'Change Loader',
  'styles.loaderAnimation.modal.header': 'Choose your loader',
  'styles.loaderAnimation.modal.saveButton': 'Save',
  'styles.loaderAnimation.modal.separator': 'Or Upload Custom Loader',
  'styles.loaderAnimation.upload.button': 'Upload Custom Loader',
  'styles.loaderAnimation.upload.change': 'Change',
  'styles.loaderAnimation.upload.header': 'Custom Loader',
  'styles.loaderAnimation.upload.description': 'SVG or GIF files are accepted with max size of 5mb',
  'seo.toast.success': 'Success',
  'integration.support.article': 'Support article',

  'integrations.dropinblog.header': 'DropInBlog Integration',
  'integrations.dropinblog.teaser':
    'Embed a blog into your site in only 3 minutes. No WordPress needed.',
  'integrations.dropinblog.code.here': 'DropInBlog code here',
  'integrations.dropinblog.note.header': 'Add the DropInBlog integration code to VSBLE',
  'integrations.dropinblog.step.1': '1',
  'integrations.dropinblog.step.2': '2',
  'integrations.dropinblog.note.1': 'Create a HTML page on your website for the blog.',
  'integrations.dropinblog.note.2':
    'Copy both codes from Dropinblog into the code window of the page.',
  'feature.share.generate.code': 'Generate code for your template?',
  'feature.share.generate.code.desc':
    'The code stores all your template color & font settings. You can re-apply it when switching templates, create variations for your template or share it with your friends.',
  'feature.share.generate.code.proceed': 'Proceed',
  'feature.share.save': 'Save custom settings',
  'feature.share.load': 'Load Custom Settings',
  'feature.share.show': 'Show your codes',
  'feature.share.maximum': 'Your maximum amount of codes exceeded, please upgrade plan',
  'feature.share.maximum.upgrade': 'Please upgrade to proceed',
  'feature.share.apply': 'Apply code here.',
  'feature.share.code': 'Website code',
  'feature.share.code.enter': 'Enter code here',
  'feature.share.apply.button': 'Apply',
  'feature.share.code.ready': 'Fonts & colors settings have been saved.',
  'feature.share.code.saved':
    'The fonts & color settings of the current design template have been saved in a code. You can copy the code now and keep it or access it anytime later from fonts & color settings. The code restores all your custom style settings of the particular template.',
  'feature.share.code.proceed': 'Proceed',
  'feature.share.copied': 'Copied!',
  'feature.share.your.codes': 'Your codes',
  'feature.share.stores':
    'The code stores all your template color & font settings. You can re-apply it when switching templates, create variations for your template or share it with your friends. Maximum amount of code on your tariff:',
  'feature.share.delete.code': 'Delete code',
  'template.change.note':
    "When you switch templates, all of your website's content is automatically saved and adjusted. Your custom settings for colors and fonts will be reset to the template defaults. If you want to use them again, you can save them first.",
  'template.change.continue': 'Continue',
  'template.change.save': 'Save color & font settings in a re-usable code first',
  'unpaid.banner.text':
    'Your website has been taken offline after several failed payment attempts.',
  'unpaid.banner.text.button': 'Select a plan',
  'cancelled.banner.text':
    'Your subscription expired. To continue using Vsble please select a subscription plan.',
  'cancelled.banner.text.button': 'Select a plan',
  'pastdue.banner.text':
    'Your recent payment attempt has failed. Please check and update your payment method to avoid account cancellation.',
  'pastdue.banner.text.button': 'Verify payment',
  'unpaid.banner.close': 'Close',
  'account.field.error.name': 'Name cannot be empty',
  'account.field.error.address1': 'Address Line 1 cannot be empty',
  'account.field.error.address2': 'Address Line 2 cannot be empty',
  'account.field.error.countryId': 'Country cannot be empty',
  'account.field.error.city': 'City cannot be empty',
  'account.field.error.zip': 'Zip cannot be empty',
  'integrations.custom.header': 'Custom Integration',
  'integrations.custom.note.header': 'Add the custom integration code to VSBLE',
  'integration.header.code': 'Header Page code',
  'integrations.custom.note.1': 'Paste your custom code into the "Header" code window of the page.',
  'integrations.custom.note.2':
    'Paste your custom code into the "Footer" code window of the page and click save.',
  'integrations.custom.code.here': 'Code here',
  'preview.banner.websiteOffline.cancelled.title':
    '<b>Your subscription has ended.</b> Please choose a plan to continue.',
  'preview.banner.websiteOffline.cancelled.select.plan': 'Select Plan',
  'addInfoAndAlt.modal.descriptive.generate': 'Auto-create with AI',
  'newDesign.album.update.field.seo.metaTitle': 'Album Meta Title',
  'newDesign.album.update.field.seo.description': 'Album Meta Description',
  'integration.custom.header.code': 'Page header code',
  'integration.custom.footer.code': 'Page body code',
  'integration.custom.header.placeholder': 'Code here',

  'styles.title.socialLinks': 'Social Links',
  'styles.socialLinks.iconType': 'Icon Type',
  'styles.socialLinks.iconType.circle': 'Circle',
  'styles.socialLinks.iconType.square': 'Square',
  'styles.socialLinks.iconType.icon': 'Icon',
  'styles.socialLinks.iconType.squareRounded': 'Square Rounded',
  'styles.socialLinks.iconColor': 'Icon Color',
  'styles.socialLinks.iconColor.black': 'Black',
  'styles.socialLinks.iconColor.white': 'White',
  'styles.socialLinks.iconColor.colored': 'Colored',
  'styles.socialLinks.heading.icons': 'Choose Icon',
  'permission.modal.pro.header': 'Unlock more features!',
  'domains.ssl.error': 'A SSL certificate could not be issued and your domain might appear as not secure to visitors. Reason: ',
  'domains.ssl.support': 'Please check the FAQ for instructions or contact support.',
  'upload.video.ratio': 'Video Ratio',
  'billing.local.price': 'The price in your local currency will be shown at checkout.',
  'website.settings.premium.option.1': 'OPTION 1',
  'website.settings.premium.option.1.buy.custom.domain': 'Buy a custom domain.',
  'website.settings.premium.option.1.buy.custom.domain.purchase': 'Purchase a custom domain from us or transfer your existing one, and connect it instantly to your Vsble website.',
  'website.settings.premium.option.1.buy': 'Buy',
  'website.settings.premium.option.1.buy.desc': 'Find and buy a custom domain.',
  'website.settings.premium.option.1.buy.watch.video': 'Watch the video',
  'website.settings.premium.option.1.find.and.buy': 'Find & Buy domain',
  'website.settings.premium.option.1.connect': 'Enter your purchased domain in the field below and click "connect".',

  'website.settings.premium.option.2': 'OPTION 2',
  'website.settings.premium.option.2.buy.custom.domain': 'Transfer your domain to us and get 10% discount.',
  'website.settings.premium.option.2.buy.custom.domain.purchase': 'Get the transfer auth code from your current provide and transfer your domain to Vsble within minutes. Then connect it to your website.',
  'website.settings.premium.option.2.buy': 'Transfer',
  'website.settings.premium.option.2.buy.desc': 'Start the transfer process by clicking below.',
  'website.settings.premium.option.2.buy.watch.video': 'Watch the video',
  'website.settings.premium.option.2.find.and.buy': 'Transfer your domain',
  'website.settings.premium.option.2.connect': 'Enter your transfered domain in the field below and click "connect".',

  'website.settings.premium.option.3': 'OPTION 3',
  'website.settings.premium.option.3.buy.custom.domain': 'Connect your existing domain without transfering it.',
  'website.settings.premium.option.3.buy.custom.domain.purchase': "If you would like to connect your custom domain but keep your current domain provider, simply update the domain's DNS records. Point the A-records of your domain to our IP: <b>94.130.249.72</b>. Watch the instructional video for popular providers like GoDaddy, Namecheap, or Ionos. The process is simple and applies to all domain providers alike.",
  'website.settings.premium.option.3.buy': 'Update DNS Records',
  'website.settings.premium.option.3.buy.desc': 'Watch the video below on how to update your domain dns records.',
  'website.settings.premium.option.3.connect': 'Enter your domain below after you have updated the DNS records and click connect.',


  'custom.domain.connect': 'Connect',
}

export default messages
