import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { intlShape, injectIntl } from 'react-intl'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import { useApolloClient, useMutation } from 'react-apollo'
import { useInterval } from 'packages/helpers/useInterval'
import { DOMAIN_TYPES } from 'packages/constants'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { useSubscription } from '@configurator/providers/subscription'
import { PREMIUM_STATUSES } from 'packages/enum'
import domainGet from '@graphql/gql/website/domainGet.gql'
import { Button } from 'packages/components/buttons/button'
import domainDeleteMutation from '@graphql/gql/website/domainDelete.gql'
import domainSetMutation from '@graphql/gql/website/domainSet.gql'
import domainRetryMutation from '@graphql/gql/website/domainRetry.gql'
// import entriTokenGetMutation from '@graphql/gql/website/entriTokenGet.gql'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import DomainForm from '../domain/DomainForm'
import {
  Paragraph,
  PremiumHeaderContainer,
  CustomDomainPreview,
  CustomDomainPreviewContainer,
  CustomDomainPreviewWrapper,
  ChangeIconWrapper,
  WrapperDescription,
  Box,
  WrapperButton,
  WrapperButtuns,
  FlexColumn,
  WrapperBox,
  // PremiumText,
} from '../nodes'
import { LockIcon } from 'packages/components/icons'
import { ChangeIcon } from 'packages/components/icons/arrows/change'
import { useWindowSize } from 'packages/helpers/useWindowSize'

// const isDebug = __ENTRI_APPLICATION_ID__.includes('staging')
// const records = __ENTRI_APPLICATION_ID__.includes('staging')
//   ? [
//       {
//         type: 'A',
//         host: '@',
//         value: '46.4.163.183',
//         ttl: 300,
//       },
//       {
//         type: 'A',
//         host: 'www',
//         value: '46.4.163.183',
//         ttl: 300,
//       },
//     ]
//   : [
//       {
//         type: 'A',
//         host: '@',
//         value: '94.130.249.72',
//         ttl: 300,
//       },
//       {
//         type: 'A',
//         host: 'www',
//         value: '94.130.249.72',
//         ttl: 300,
//       },
//     ]

const PremiumSection = ({ websiteId, freeDomain, isMobile, intl: { formatMessage } }) => {
  const client = useApolloClient()
  const size = useWindowSize()

  const dispatch = useDispatch()
  const { permissions } = useSubscription()

  const [startPremiumDomainRequest, setStartPremiumDomainRequest] = useState(false)

  const [premiumDomainInfo, setPremiumDomainInfo] = useState({})

  // const [entriSellLoading, setEntriSellLoading] = useState(false)

  // const [entriConnectLoading, setEntriConnectLoading] = useState(false)

  // const [entriTokenGet] = useMutation(entriTokenGetMutation)
  const [domainDelete] = useMutation(domainDeleteMutation)
  const [domainSet] = useMutation(domainSetMutation)
  const [domainRetry] = useMutation(domainRetryMutation)

  const deletePremiumDomain = () =>
    domainDelete({
      variables: {
        requestId: premiumDomainInfo.id,
      },
    }).then(
      ({
        data: {
          domainDelete: { success },
        },
      }) => {
        if (success) {
          setPremiumDomainInfo({ isRequestExist: false, domain: '' })
          setStartPremiumDomainRequest(false)
        }
      }
    )

  // toggle change domain
  const changePremiumDomain = useCallback(
    async ({ domain }) => {
      const data = await domainSet({
        variables: {
          websiteId,
          domain,
        },
      })
      if (get(data, 'data.domainSet.errors._error')) {
        dispatch(
          openErrorModal({
            headerMessageId: 'error.header',
            yesMessageId: 'OK',
            subMessageId: get(data, 'data.domainSet.errors._error'),
          })
        )
      } else {
        setStartPremiumDomainRequest(true)
      }
    },
    [dispatch, domainSet, websiteId]
  )

  useEffect(() => {
    const handleOnEntriClose = (event) => {
      console.log('onEntriClose', event.detail)
      if (event.detail && event.detail.success) {
        if (event.detail.setupType === 'purchase') {
          // domain: "ladoalexi.com"
          // freeDomain: false
          // lastStatus: "FINISHED_SUCCESSFULLY"
          // provider: "ionos"
          // setupType: "purchase"
          // success: true

          if (
            event.detail.lastStatus === 'FINISHED_SUCCESSFULLY' ||
            event.detail.lastStatus === 'FINISHED_SUCCESSFULLY_MANUAL'
          ) {
            // check if custom domain is already connected
            changePremiumDomain({ domain: event.detail.domain })
          }
        } else {
          // domain: "ladoalexi.com"
          // lastStatus: "FINISHED_SUCCESSFULLY_MANUAL"
          // provider: ""
          // setupType: null
          // success: true

          changePremiumDomain({ domain: event.detail.domain })
        }
      }
    }
    console.log('registered onEntriClose')
    window.addEventListener('onEntriClose', handleOnEntriClose, false)

    return () => window.removeEventListener('onEntriClose', handleOnEntriClose, false)
  }, [changePremiumDomain])

  // toggle change domain
  const retryDomainCheck = async () => {
    await domainRetry()
    setStartPremiumDomainRequest(true)
  }

  // const connectEtriDomain = async () => {
  //   setEntriConnectLoading(true)

  //   const res = await entriTokenGet()

  //   if (get(res, 'data.entriTokenGet.errors._error')) {
  //     dispatch(
  //       openErrorModal({
  //         headerMessageId: 'error.header',
  //         yesMessageId: 'OK',
  //         subMessageId: get(res, 'data.entriTokenGet.errors._error'),
  //       })
  //     )
  //   } else {
  //     if (window.entri) {
  //       const config = {
  //         applicationId: __ENTRI_APPLICATION_ID__,
  //         token: res.data.entriTokenGet.data,
  //         debugMode: isDebug,
  //         dnsRecords: records,
  //         supportForSubdomains: false,
  //       }
  //       window.entri.showEntri(config)
  //     } else {
  //       dispatch(
  //         openErrorModal({
  //           headerMessageId: 'error.header',
  //           yesMessageId: 'OK',
  //           subMessageId: 'Entri provider loading error. Please try again.',
  //         })
  //       )
  //     }
  //   }

  //   setEntriConnectLoading(false)
  // }

  // const sellEtriDomain = async () => {
  //   if (premiumDomainInfo.domainName) {
  //     dispatch(
  //       openErrorModal({
  //         headerMessageId: 'Oops. Lools like you already have a domain',
  //         yesMessageId: 'OK',
  //         subMessageId: 'Remove your current domain before adding a new one',
  //       })
  //     )
  //   } else {
  //     setEntriSellLoading(true)

  //     const res = await entriTokenGet()

  //     if (get(res, 'data.entriTokenGet.errors._error')) {
  //       dispatch(
  //         openErrorModal({
  //           headerMessageId: 'error.header',
  //           yesMessageId: 'OK',
  //           subMessageId: get(res, 'data.entriTokenGet.errors._error'),
  //         })
  //       )
  //     } else {
  //       if (window.entri) {
  //         const config = {
  //           applicationId: __ENTRI_APPLICATION_ID__,
  //           token: res.data.entriTokenGet.data,
  //           debugMode: isDebug,
  //           dnsRecords: records,
  //           sellVersion: 'v3',
  //         }
  //         window.entri.purchaseDomain(config)
  //       } else {
  //         dispatch(
  //           openErrorModal({
  //             headerMessageId: 'error.header',
  //             yesMessageId: 'OK',
  //             subMessageId: 'Entri provider loading error. Please try again.',
  //           })
  //         )
  //       }
  //     }

  //     setEntriSellLoading(false)
  //   }
  // }

  const updateRequestStatus = useCallback(() => {
    client
      .query({
        query: domainGet,
        variables: { websiteId },
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            domainGet: { data, success },
          },
        }) => {
          if (success) {
            setPremiumDomainInfo(data)
          }
        }
      )
  }, [client, websiteId])

  useEffect(() => {
    if (websiteId && client) {
      updateRequestStatus(true)
    }
  }, [startPremiumDomainRequest, websiteId, client, updateRequestStatus])

  useInterval(() => {
    if (
      startPremiumDomainRequest &&
      premiumDomainInfo.isRequestExist &&
      premiumDomainInfo.status !== PREMIUM_STATUSES.ACTIVE
    ) {
      updateRequestStatus()
    }
  }, 10000)

  return (
    <WrapperBox isMobile={isMobile}>
      <PremiumHeaderContainer>
        <Paragraph
          type='h1'
          fz={'24px'}
          fw={700}
          mb={24}
          color='#242426'
          data-intercom-target={'ConnectCustomDomain'}
        >
          {formatMessage({
            id: 'website.settings.premium.title',
          })}
        </Paragraph>

        <WrapperDescription>
          <div>
            <Paragraph
              type='h2'
              fz={16}
              fw={300}
              mw={456}
              lh={'28px'}
              color='#242426'
              textAlign='left'
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'website.settings.premium.text1',
                }),
              }}
            />
            <Paragraph
              type='h2'
              fz={16}
              mt={16}
              fw={300}
              mw={456}
              lh={'28px'}
              color='#242426'
              textAlign='left'
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'website.settings.premium.text1.1',
                }),
              }}
            />
          </div>
          <CustomDomainPreviewWrapper width={size.clientWidth}>
            <CustomDomainPreviewContainer>
              <CustomDomainPreview>
                <div>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div>
                  <div>
                    <span>LA</span>
                  </div>
                  <div>
                    <div>
                      <LockIcon width={7} />
                    </div>
                    <div>
                      https://<span>{freeDomain}</span>
                      .vsble.me
                    </div>

                    <ChangeIconWrapper>
                      <ChangeIcon height={60} />
                    </ChangeIconWrapper>
                  </div>
                </div>
              </CustomDomainPreview>
              <div></div>
            </CustomDomainPreviewContainer>

            <CustomDomainPreviewContainer>
              <CustomDomainPreview>
                <div>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div>
                  <div>
                    <span>LA</span>
                  </div>
                  <div>
                    <div>
                      <LockIcon width={7} />
                    </div>
                    <div>https://yourdomain.com</div>
                  </div>
                </div>
              </CustomDomainPreview>
            </CustomDomainPreviewContainer>
            <div></div>
          </CustomDomainPreviewWrapper>
        </WrapperDescription>
      </PremiumHeaderContainer>

      <Box isPremiumDomain isMobile={isMobile}>
        <Paragraph type='h3' fz={12} fw={300} color='#242426' mb={0}>
          {formatMessage({ id: 'website.settings.premium.option.1' })}
        </Paragraph>
        <br />

        <Paragraph
          type='h2'
          mb={17}
          fw={700}
          color='#242426'
          isPremiumDomain
          data-intercom-target={'WebsiteBuyCustomDomain'}
        >
          {formatMessage({ id: 'website.settings.premium.option.1.buy.custom.domain' })}
        </Paragraph>

        <Paragraph type='h2' fz={17} fw={300} color='#242426' mb={24}>
          {formatMessage({ id: 'website.settings.premium.option.1.buy.custom.domain.purchase' })}
        </Paragraph>

        <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
          <WrapperButtuns>
            <WrapperButton isPremiumDomain>
              <FlexColumn style={{ minWidth: '300px' }}>
                <Paragraph type='h2' fw={700} color='#242426'>
                  1. {formatMessage({ id: 'website.settings.premium.option.1.buy' })}
                </Paragraph>
                <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                  {formatMessage({ id: 'website.settings.premium.option.1.buy.desc' })}
                  <br />
                  <Paragraph
                    as={'a'}
                    type='h3'
                    fw={500}
                    color='#242426'
                    href='https://www.youtube.com/watch?v=Zc6q3e5R2T0'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {formatMessage({ id: 'website.settings.premium.option.1.buy.watch.video' })}
                  </Paragraph>
                </Paragraph>

                <Button
                  as='a'
                  href='https://domains.vsble.me'
                  type='submit'
                  target='_blank'
                  rel='noopener noreferrer'
                  view='primary'
                  content={formatMessage({ id: 'website.settings.premium.option.1.find.and.buy' })}
                />
              </FlexColumn>
            </WrapperButton>
            <FlexColumn style={{ minWidth: '56%' }}>
              <Paragraph type='h2' fw={700} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
              </Paragraph>
              <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.option.1.connect' })}
              </Paragraph>
              <DomainForm
                type={DOMAIN_TYPES.PREMIUM_DOMAIN}
                domain={premiumDomainInfo.domainName}
                changeDomain={changePremiumDomain}
                deleteDomain={deletePremiumDomain}
                domainRetry={retryDomainCheck}
                premiumStatus={premiumDomainInfo.status}
                failureCode={premiumDomainInfo.failureCode}
                attemptCount={premiumDomainInfo.attemptCount}
                lastAttemptDate={premiumDomainInfo.lastAttemptDate}
                isRequestExist={premiumDomainInfo.isRequestExist}
                isMobile={isMobile}
              />
            </FlexColumn>
          </WrapperButtuns>
        </PermissionsOverlay>
      </Box>

      <Box isPremiumDomain isMobile={isMobile}>
        <Paragraph type='h3' fz={12} lh={'28px'} fw={300} color='#242426' mb={0}>
          {formatMessage({ id: 'website.settings.premium.option.2' })}
        </Paragraph>
        <br />

        <Paragraph
          type='h2'
          mb={17}
          fw={700}
          color='#242426'
          isPremiumDomain
          data-intercom-target={'WebsiteBuyCustomDomain'}
        >
          {formatMessage({ id: 'website.settings.premium.option.2.buy.custom.domain' })}
        </Paragraph>

        <Paragraph type='h2' fz={17} lh={'28px'} fw={300} color='#242426' mb={24}>
          {formatMessage({ id: 'website.settings.premium.option.2.buy.custom.domain.purchase' })}
        </Paragraph>

        <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
          <WrapperButtuns>
            <WrapperButton isPremiumDomain>
              <FlexColumn style={{ minWidth: '300px' }}>
                <Paragraph type='h2' fw={700} color='#242426'>
                  1. {formatMessage({ id: 'website.settings.premium.option.2.buy' })}
                </Paragraph>
                <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                  {formatMessage({ id: 'website.settings.premium.option.2.buy.desc' })}
                  <br />
                  <Paragraph
                    as={'a'}
                    type='h3'
                    fw={500}
                    color='#242426'
                    href='https://www.youtube.com/watch?v=Zc6q3e5R2T0'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {formatMessage({ id: 'website.settings.premium.option.2.buy.watch.video' })}
                  </Paragraph>
                </Paragraph>

                <Button
                  as='a'
                  href='https://domains.vsble.me'
                  type='submit'
                  target='_blank'
                  rel='noopener noreferrer'
                  view='primary'
                  content={formatMessage({ id: 'website.settings.premium.option.2.find.and.buy' })}
                />
              </FlexColumn>
            </WrapperButton>
            <FlexColumn style={{ minWidth: '56%' }}>
              <Paragraph type='h2' fw={700} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
              </Paragraph>
              <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.option.2.connect' })}
              </Paragraph>
              <DomainForm
                type={DOMAIN_TYPES.PREMIUM_DOMAIN}
                domain={premiumDomainInfo.domainName}
                changeDomain={changePremiumDomain}
                deleteDomain={deletePremiumDomain}
                domainRetry={retryDomainCheck}
                premiumStatus={premiumDomainInfo.status}
                failureCode={premiumDomainInfo.failureCode}
                attemptCount={premiumDomainInfo.attemptCount}
                lastAttemptDate={premiumDomainInfo.lastAttemptDate}
                isRequestExist={premiumDomainInfo.isRequestExist}
                isMobile={isMobile}
              />
            </FlexColumn>
          </WrapperButtuns>
        </PermissionsOverlay>
      </Box>

      <Box isPremiumDomain isMobile={isMobile}>
        <Paragraph type='h3' fz={12} lh={'28px'} fw={300} color='#242426' mb={0}>
          {formatMessage({ id: 'website.settings.premium.option.3' })}
        </Paragraph>
        <br />

        <Paragraph
          type='h2'
          mb={17}
          fw={700}
          color='#242426'
          isPremiumDomain
          data-intercom-target={'WebsiteBuyCustomDomain'}
        >
          {formatMessage({ id: 'website.settings.premium.option.3.buy.custom.domain' })}
        </Paragraph>

        <Paragraph
          type='h2'
          fz={17}
          lh={'28px'}
          fw={300}
          color='#242426'
          mb={24}
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: 'website.settings.premium.option.3.buy.custom.domain.purchase',
            }),
          }}
        ></Paragraph>

        <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
          <WrapperButtuns>
            <WrapperButton isPremiumDomain>
              <FlexColumn style={{ minWidth: '300px' }}>
                <Paragraph type='h2' fw={700} color='#242426'>
                  1. {formatMessage({ id: 'website.settings.premium.option.3.buy' })}
                </Paragraph>
                <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                  {formatMessage({ id: 'website.settings.premium.option.3.buy.desc' })}
                </Paragraph>
                <iframe
                  style={{ width: '100%', border: 'none', height: '200px' }}
                  src='https://www.youtube.com/embed/IAjB3K_GuZE'
                  frameborder='0'
                  allowfullscreen='allowfullscreen'
                  title='YouTube video player'
                ></iframe>
              </FlexColumn>
            </WrapperButton>
            <FlexColumn style={{ minWidth: '56%' }}>
              <Paragraph type='h2' fw={700} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
              </Paragraph>
              <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                {formatMessage({ id: 'website.settings.premium.option.3.connect' })}
              </Paragraph>
              <DomainForm
                type={DOMAIN_TYPES.PREMIUM_DOMAIN}
                domain={premiumDomainInfo.domainName}
                changeDomain={changePremiumDomain}
                deleteDomain={deletePremiumDomain}
                domainRetry={retryDomainCheck}
                premiumStatus={premiumDomainInfo.status}
                failureCode={premiumDomainInfo.failureCode}
                attemptCount={premiumDomainInfo.attemptCount}
                lastAttemptDate={premiumDomainInfo.lastAttemptDate}
                isRequestExist={premiumDomainInfo.isRequestExist}
                isMobile={isMobile}
              />
            </FlexColumn>
          </WrapperButtuns>
        </PermissionsOverlay>
      </Box>

      {/* <Box isPremiumDomain isMobile={isMobile}>
        <PremiumText
          type='h2'
          mb={17}
          fw={700}
          color='#242426'
          isPremiumDomain
          data-intercom-target={'WebsiteConnectCustomDomain'}
        >
          {formatMessage({ id: 'website.settings.premium.text12' })}
        </PremiumText>

        <Paragraph type='h2' fz={17} lh={'28px'} fw={300} color='#242426' mb={24}>
          {formatMessage({ id: 'website.settings.premium.text13' })}
        </Paragraph>

        {!premiumDomainInfo.domainName ? (
          <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
            <WrapperButton isPremiumDomain>
              <Button
                loading={entriConnectLoading}
                type='submit'
                view='primary'
                onClick={() => connectEtriDomain()}
                content={formatMessage({ id: 'website.settings.premium.text14' })}
              />
            </WrapperButton>
          </PermissionsOverlay>
        ) : null}
      </Box> */}
    </WrapperBox>
  )
}

PremiumSection.propTypes = {
  websiteId: PropTypes.number.isRequired,
  freeDomain: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  isMobile: PropTypes.bool,
}

PremiumSection.defaultProps = {}

export default injectIntl(PremiumSection)
