import styled, { css } from 'styled-components'

export const Container = styled.div`
  border: 1px solid #ededed;
  background: #fff;
  padding: 30px 40px;
  border-radius: 5px;

  @media (max-width: 540px) {
    padding: 24px;
  }

  @media (max-width: 375px) {
    padding: 20px;
  }
`
export const Header = styled.div`
  color: #343840;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
`

export const Subheading = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
`

export const SubscriptionCard = styled.div`
  margin-top: 30px;
  border: 1px solid #ededed;
  border-radius: 5px;

  background: #fff;
  padding: 33px 40px;

  @media (max-width: 1000px) {
    padding: 20px 55px;
  }

  @media (max-width: 540px) {
    padding: 24px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const LeftBlock = styled.div``

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const TitleBlock = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e2e6;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 17px;
  }
`

export const CurrentPlan = styled.div`
  min-width: 150px;

  @media (max-width: 1000px) {
    text-align: center;
  }
`

export const TextInterval = styled.span`
  margin-right: 4px;
`

export const TextBold = styled.span`
  font-weight: 700;
  margin-right: 4px;
`

export const Status = styled.div`
  align-self: flex-start;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  border-radius: 3px;
  padding: 6px 10px;
  text-align: center;

  ${({ status }) => {
    if (status === 'active') {
      return css`
        color: #25b772;
        background: #f3fff1;
      `
    }
    if (status === 'error') {
      return css`
        color: #ed457a;
        background: #fedfd4;
      `
    }
  }}

  @media (max-width: 1000px) {
    align-self: center;
  }
`
export const HiddenStatus = styled.div`
  visibility: hidden;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const Name = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: 1000px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`
export const Expire = styled.div`
  margin-top: 6px;

  font-size: 15px;
  font-weight: 300;
  line-height: 20px;

  @media (max-width: 1000px) {
    text-align: center;
  }
`
export const ComparePlans = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 12px;

  @media (max-width: 1000px) {
    margin-left: 0;
    justify-content: center;
  }
`

export const AlertBlock = styled.div`
  color: #000;

  max-width: 650px;
  width: 100%;
  margin: 22px 0 0 auto;
  padding: 14px 33px 14px 26px;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  background: #ffb926;
  border-radius: 5px;
  border: 1px solid #ededed;

  text-align: left;



  ${(props) =>
    props.isCancelAtPeriodEnd &&
    css`
      display: flex;
      justify-content: space-between;

      & button {
        color: white;
        text-decoration: underline;
        background: transparent;
        border: none;
        cursor: pointer;
      }
    `}};

  @media (max-width: 1000px) {
    padding: 20px 28px;
    margin: 18px 0 0 auto;
    text-align: center;
  }
`

export const CompareButton = styled.a`
  cursor: pointer;
  color: #2eba76;

  font-size: 15px;
  font-weight: 300;
  line-height: 24px;

  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`
export const UpgradeButton = styled.a`
  cursor: pointer;

  color: #fff;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  padding: 15px 24px;
  border-radius: 4px;
  background: #58c367;

  &:hover {
    color: #fff;
  }
`
