import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import moment from 'moment'

import { Button } from 'packages/components/buttons'

import { Expire, Status, TextBold, TextInterval } from '../../nodes'
import Card from '../Card'

const PaidCard = ({
  planName,
  renewsOn,
  onManageSubscription,
  interval,
  cancelAtPeriodEnd,
  intl: { formatMessage },
}) => {
  const expireDate = moment(renewsOn)

  const expireText = expireDate.format('DD.MM.YYYY')

  return (
    <Card
      planName={planName}
      Status={
        cancelAtPeriodEnd ? (
          <Status status='error'>
            {formatMessage({ id: 'accountBilling.billing.status.cancelled' })}
          </Status>
        ) : (
          <Status status='active'>
            {formatMessage({ id: 'accountBilling.billing.status.active' })}
          </Status>
        )
      }
      onManageSubscription={onManageSubscription}
      isCancelAtPeriodEnd={cancelAtPeriodEnd}
      alertBlock={
        cancelAtPeriodEnd && formatMessage({ id: 'accountBilling.billing.alert.canceled' })
      }
      RightBlock={
        <Button view='secondaryBlack' onClick={onManageSubscription}>
          {formatMessage({ id: 'accountBilling.billing.manage' })}
        </Button>
      }
      Expire={
        <Expire>
          <TextInterval>
            {formatMessage({ id: 'accountBilling.billing.paymentInterval' })}:
          </TextInterval>

          <TextBold>{interval}.</TextBold>

          <TextInterval>
            {cancelAtPeriodEnd
              ? formatMessage({ id: 'accountBilling.billing.expiresOn' })
              : formatMessage({ id: 'accountBilling.billing.renewsOn' })}
            :
          </TextInterval>

          <TextBold>{expireText}</TextBold>
        </Expire>
      }
    />
  )
}

PaidCard.propTypes = {
  intl: intlShape.isRequired,
  subscription: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  cancelAtPeriodEnd: PropTypes.bool,
}

PaidCard.defaultProps = {
  subscription: [],
}

export default injectIntl(PaidCard)
