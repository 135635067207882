import { Semantic } from 'packages/components'
import { CaretDownIcon } from 'packages/components/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { AccordionContent, AccordionTitle } from './nodes'

const Accordion = ({ data, id, pushIdx, remIdx, setIdx, activeIndices, multipleSelection }) => {
  const handleClick = ({ index }) => {
    const ind = activeIndices.findIndex((el) => el === index)
    if (ind === -1) {
      if (multipleSelection) {
        pushIdx(index)
      } else {
        setIdx(index)
      }
    } else {
      remIdx(ind)
    }
  }

  const isActive = (index) => {
    return activeIndices.findIndex((el) => el === index) !== -1
  }

  return (
    <Semantic.Accordion id={id}>
      {data.length > 0
        ? data.map((item) => (
            <React.Fragment key={item.key}>
              <AccordionTitle active={isActive(item.key)} index={item.key}>
                {item.title}
                {item.content && (
                  <span onClick={() => handleClick({ index: item.key })}>
                    <CaretDownIcon color='gray' />
                  </span>
                )}
              </AccordionTitle>
              {item.content && (
                <AccordionContent active={isActive(item.key)}>{item.content}</AccordionContent>
              )}
            </React.Fragment>
          ))
        : null}
    </Semantic.Accordion>
  )
}

Accordion.propTypes = {
  data: PropTypes.array.isRequired,
  pushIdx: PropTypes.func.isRequired,
  remIdx: PropTypes.func.isRequired,
  setIdx: PropTypes.func.isRequired,
  activeIndices: PropTypes.object,
  multipleSelection: PropTypes.bool,
  id: PropTypes.string,
}

Accordion.defaultProps = {
  activeIndices: [],
  multipleSelection: false,
  id: undefined,
}

export default Accordion
